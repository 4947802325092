import React, {useEffect, useState} from 'react';
import {Flex, Col, Row, Button, Menu, Image, Layout, Dropdown, Space, ConfigProvider} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import "./custom-sider.css";
import {IMG_URL} from '../../constants/index';
import {ReactComponent as JobIcon} from '../../assets/img/icon_jobs.svg';
import {ReactComponent as AddJobIcon} from '../../assets/img/icon_new.svg';
import {ReactComponent as PaymentIcon} from '../../assets/img/icon_pay.svg';
import {ReactComponent as PlansIcon} from '../../assets/img/icon_plans.svg';
import UserNoImg from '../../assets/img/user_no_image.svg';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
} from '@ant-design/icons';
const {Sider} = Layout;

const getMenuItem = (label, key, icon, children, type) => {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const menuItems = [
    getMenuItem((<Link to='/job-offers'>Job Offers</Link>), '1', <JobIcon />),
    getMenuItem((<Link to='/add-job'>Add a job</Link>), '2', <AddJobIcon />),
    getMenuItem((<Link to='/payment-list'>Payments</Link>), '3', <PaymentIcon />),
    getMenuItem((<Link to='/subscription'>Subscription Plans</Link>), '4', <PlansIcon />),
];

const LogoutButton = () => {
    const navigate = useNavigate();

    const logoutFunc = () => {
        console.log("logoutFunc")
        localStorage.removeItem("accessToken");
        localStorage.removeItem("profileStatus");
        navigate("/login");
    }

    return (
        <Button
            onClick={(ev) => {
                ev.preventDefault()
                logoutFunc()
            }}
            type="text"
        >
            Logout
        </Button>
    )
}

const items = [
    {
        label: <LogoutButton />,
        key: '0',
    },
];

const CustomSider = ({company}) => {
    const [collapsed, setCollapsed] = useState(false);

    const [windowSize, setWindowSize] = useState([
        window.innerHeight,
        window.innerWidth,
    ]);

    const getUserImage = (src) => {
        if (src) {
            return IMG_URL + '/' + src
        } else {
            return UserNoImg
        }
    }

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowSize([window.innerHeight, window.innerWidth]);
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    useEffect(() => {

        if (windowSize[1] <= 1199) {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }, [windowSize])

    useEffect(() => {
        if (!company) return
    }, [company])

    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        itemBorderRadius: '0',
                        itemSelectedBg: 'transparent',
                        itemActiveBg: 'transparent',
                        itemHoverBg: '#f2e7ea',
                        itemMarginInline: 0,
                        itemHoverColor: '#732135',
                    },
                    Button: {
                        defaultBorderColor: '#732135'
                    },
                    Dropdown: {
                        controlItemBgHover: 'none'
                    },
                },
            }}
        >
            <Sider
                theme="light"
                trigger={null}
                className="custom-sider-style"
                collapsible
                collapsed={collapsed}
                collapsedWidth={122}
                width={360}
            >
                <div className="custom-sider-content">
                    {/* <div className="collapsed-btn-block"> */}
                    <div style={{textAlign: 'left'}}>
                        <Button
                            className="sider-collapsed-btn"
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: '100%',
                                height: 47,
                            }}
                        />
                    </div>
                    {/* </div> */}
                    <Flex gap="middle" vertical className="h-100" justify='space-between'>
                        <Flex align="center" justify="space-between" vertical>
                            <Menu
                                className='company-profile-menu custom-sider-menu w-100'
                                defaultSelectedKeys={['1']}
                                mode="inline"
                                items={menuItems}
                            />
                        </Flex>
                        <div className="custom-sider-footer">
                            <div className="sider-footer-content">
                                <Image src={getUserImage(company?.logo?.path)} preview={false} />
                                <div className="sider-footer-text">
                                    <h4>{company.name}</h4>
                                    <Flex gap="middle" align="center" justify='space-between' horizontal='true'>
                                        <Link to='/profile'>View Profile</Link>
                                        <Dropdown
                                            menu={{items}}
                                            placement="topRight"
                                            trigger={['click']}
                                        >
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    <SettingOutlined />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </Flex>
                </div>
            </Sider>
        </ConfigProvider>
    )
}

export default CustomSider;
import React, {useState, useEffect} from 'react';
import {ReactComponent as SearchIcon} from "../../assets/img/icon_search.svg";
import {useSelector} from 'react-redux';

import {
  Col,
  Row,
  Input,
  Checkbox,
  Space,
  DatePicker,
  ConfigProvider,
  InputNumber,
} from 'antd';

const {TextArea} = Input;

const CompanyDetailsStepTwo = ({apiService, setCompanyInfoForm, companyInfoForm}) => {
  const [checkedList, setCheckedList] = useState([]);
  const categories = useSelector(state => state.launch.jobOfferCategory);
  const [filteredOptions, setFilteredOptions] = useState(null);

  const onChangeActivity = (list) => {
    console.log("list", list)
    setCompanyInfoForm({...companyInfoForm, domainActivity: [...list]});
  };

  const onChangeDate = (date) => {
    setCompanyInfoForm({...companyInfoForm, year: new Date(date)});
  };

  const handleEmployeChange = (value) => {
    setCompanyInfoForm({...companyInfoForm, employers: value.toString()});
  };

  const handleAditionalInfo = (event) => {
    setCompanyInfoForm({...companyInfoForm, description: event.target.value});
  }

  const onSearchFunc = (ev) => {
    let searchVal = ev.target.value.toLowerCase()
    if (categories.length > 0) {
      if (searchVal.length > 0) {
        let filteredCategories = categories.filter(item => item.description?.toLowerCase().includes(searchVal) || item.title.toLowerCase().includes(searchVal))
        setFilteredOptions(filteredCategories)
      } else {
        setFilteredOptions(categories)
      }
    }
  }

  useEffect(() => {
    if (categories.length > 0) {
      setFilteredOptions(categories)
    }
  }, [categories])

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            hoverBorderColor: '#732135',
            activeBorderColor: '#732135',
            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
            colorPrimary: '#732135',
            optionSelectedColor: '#ffffff',
          },
          InputNumber: {
            activeBorderColor: '#732135',
            hoverBorderColor: '#732135',
            controlOutline: 'rgba(115, 34, 52, 0.1);',
            optionSelectedBg: '#732135',
          },
          Input: {
            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
            activeBorderColor: '#732135',
            hoverBorderColor: '#732135',
            colorBorder: '#aeaeae',
          },
        },
      }}
    >
      <Row className='company-details-step' gutter={[12, 36]}>
        <Col xs={24} lg={12} className='step-two-aditional-info'>
          <h3>Sign Up for free</h3>
          <h1>Company aditional info</h1>
          <Space direction="horizontal">
            <div>
              <div className="activity-name">
                Year of foundation:
              </div>
              <DatePicker onChange={onChangeDate} picker="year" />
            </div>
            <div>
              <div className="activity-name">
                Number of employers:
              </div>
              <InputNumber min={1} defaultValue={1} onChange={handleEmployeChange} />
            </div>
          </Space>
          <div>
            <div className="activity-name">
              Aditional Info:
            </div>
            <TextArea rows={7} onChange={handleAditionalInfo} />
          </div>
        </Col>
        <Col xs={24} lg={{span: 11, offset: 1}}>
          <div className="form-section search-form-section">
            <Input placeholder="Search..." allowClear onChange={onSearchFunc} prefix={<SearchIcon />} />
          </div>
          <h3>Choose domain of activity:</h3>
          <div className="company-activity-list">
            <Checkbox.Group onChange={onChangeActivity}>
              <Row>
                <Col span={24}>
                  {filteredOptions && filteredOptions.map((item) => (
                    <Checkbox key={item.id.toString()} value={item.id.toString()}>
                      <Space direction="vertical" gutter={12}>
                        <div className="activity-name">
                          {item.title}
                        </div>
                        <div className="acivity-descryption">
                          {item.description}
                        </div>
                      </Space>
                    </Checkbox>
                  ))}
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </Col>
      </Row>
    </ConfigProvider>

  )
}

export default CompanyDetailsStepTwo
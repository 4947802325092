import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import './login.css';
import apiService from "../../services/api.service";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {setIsModalOpen} from '../../slices/login';
import LoginImg from '../../assets/img/image_login.png';
import {Layout, Flex, Steps, Col, Row, Button, Modal, Image, Form, Checkbox, Input, ConfigProvider, notification, Space} from 'antd';

const Login = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(state => state.login.isModalOpen);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const messageApi = useOutletContext();
    const [notifications, notificationContextHolder] = notification.useNotification();

    const key = `open${Date.now()}`;
    const btn = (
        <Space>
            <div className="custom-btn-block">
                <Button
                    htmlType="submit"
                    block
                    className='w-100'
                    onClick={() => {
                        notifications.destroy(key)
                        navigate("/");
                        dispatch(setIsModalOpen(false))
                    }}
                >
                    Create an account
                </Button>
            </div>
        </Space>
    );

    const closeNotifications = () => {
        console.log(
            'Notification was closed. Either the close button was clicked or duration time elapsed.',
        );
    };

    const onFinish = async (values) => {
        form.getFieldsError()
        try {
            await apiService.onLoginUser({
                email: values.email,
                password: values.password
            }).then(response => {
                console.log("onFinish response", response)
                if (response.data.profileStatus === 'company') {
                    localStorage.setItem("accessToken", response.data.accessToken);
                    localStorage.setItem("refreshToken", response.data.refreshToken);
                    localStorage.setItem("profileStatus", response.data.profileStatus);

                    if (response.status === 200) {
                        dispatch(setIsModalOpen(false))
                        form.resetFields()
                        navigate("/profile");
                    }
                } else {
                    notifications.open({
                        message: 'Notification Title',
                        description:
                            'A function will be be called after the notification is closed (automatically after the "duration" time of manually).',
                        btn,
                        key,
                        onClose: closeNotifications,
                    });
                }
            });
        } catch (error) {
            console.log("Login error", error)
            messageApi.open({
                className: 'open-message-style',
                duration: 5,
                type: 'error',
                content: error.response.data.message,
            });
            // form.resetFields()
            form.setFields([
                {
                    name: "email",
                    errors: ['Wrong email']
                },
                {
                    name: "password",
                    errors: ['Wrong password']
                }
            ])
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.resetFields()
    }, [isModalOpen])

    return (
        <div className='login-modal-block container' >
            <ConfigProvider
                theme={{
                    components: {
                        Input: {
                            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                            activeBorderColor: '#732135',
                            hoverBorderColor: '#732135',
                            colorBorder: '#aeaeae',
                        },
                        Checkbox: {
                            colorPrimary: '#732135',
                            colorPrimaryHover: '#732135',
                            colorBorder: '#aeaeae',
                        }
                    },
                }}
            >
                {notificationContextHolder}
                <Row>
                    <Col xs={24} lg={12} className='hello-block'>
                        <Image
                            preview={false}
                            src={LoginImg}
                        />
                        <h3 className='text-center'>Very good works are waiting for you.</h3>
                        <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </Col>
                    <Col xs={24} lg={12} className='login-block'>
                        <h2>Login</h2>
                        <p>Welcome back, please login to your account</p>
                        <Form
                            form={form}
                            name="loginForm"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email:"
                                name="email"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                className='password-style'
                                label="Password:"
                                name="password"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                            >
                                <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                    <Checkbox defaultChecked={true}>Remember me</Checkbox>
                                    <Link to='/recover-password' className='forgot-password'>Forgot Password?</Link>
                                </Flex>
                            </Form.Item>

                            <Form.Item className='sign-in-style'>
                                <div className="custom-btn-block">
                                    <Button htmlType="submit" block className='w-100'>
                                        Sign in
                                    </Button>
                                </div>
                            </Form.Item>
                            <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                <p className='have-account'>Don’t you have an account?</p>
                                <Link to='/' className='create-account-link'>Create an account</Link>
                            </Flex>
                        </Form>
                        <div className="login-copyright-block">
                            <p className='text-center'>Copyright 2024 JOB123 LTD.</p>
                            <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                <Link to='/privacy-policy' target='_blank' >Privacy Policy</Link>
                                <Link to='/term-of-use' target='_blank' >Term of Use</Link>
                            </Flex>
                        </div>
                    </Col>
                </Row>
            </ConfigProvider>
        </div>
    )
}

export default Login
import React from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
// import Loader from "../loader/Loader";

const stripePromise = loadStripe('pk_test_FQRkJ2sDhk0hjqOfSpsmoMcc');


const StripeProcessing = () => {

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );
    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#ee703d',
            colorText: '#094267',
        },
    };

    let options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#732135',
                colorBackground: '#ffffff',
                colorText: '#2a2a35',
                colorDanger: '#df1b41',
                spacingUnit: '2px',
                borderRadius: '4px',
            },
            rules: {
                '.Input': {
                    lineHeight: '40px',
                    borderColor: '#AEAEAD'
                },
                '.Tab': {
                    lineHeight: '22px',
                    borderColor: '#AEAEAD'
                },
                '.Label': {
                    margin: '10px 0 5px',
                },
            }
        },

    };

    return (
        <>
            {options.clientSecret && (
                <div className="container">
                    <Elements options={options} stripe={stripePromise}>
                        <StripeCheckoutForm />
                    </Elements>
                </div>
            )}
        </>);
}
export default StripeProcessing;

import {React, useEffect, useState} from "react";
import {Form, Radio, Flex, Row, Col, Image} from 'antd';
import BottomImg from "../../assets/img/bottom-img.png";
import "./footer.css";

const Footer = () => {
    return (
        <>
            <Row className='bottom-images-style'>
                <Col>
                    <Image
                        src={BottomImg}
                        preview={false}
                    />
                </Col>
            </Row>
            <div className="footer">
                <Flex justify="space-between" align="center" className="container footer-container">
                    <div className="footer-menu">
                        <ul>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Legal Notice</a></li>
                            <li><a href="#">Change Cookie Settings</a></li>
                        </ul>
                    </div>
                    <div className="copyright-text">© 2023 JOB Finder</div>
                </Flex>
            </div>
        </>
    )
}

export default Footer;
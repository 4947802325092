import Root from './Root.jsx'
import {createBrowserRouter} from "react-router-dom";

import App from '../App.jsx';
import CompanyProfile from '../components/company-profile/CompanyProfile.jsx';
import CompanyDetails from '../components/company-details/CompanyDetails.jsx';
import {JobOffers} from '../components/job-offers/JobOffers.jsx';
import AddJob from '../components/add-job/AddJob.jsx';
import Subscription from '../components/subscription/Subscription.jsx';
import PrivacyPolicy from '../components/privacy-policy/PrivacyPolicy.jsx';
import TermOfUse from '../components/term-of-use/TermOfUse.jsx';
import Login from '../components/login-form/Login.jsx';
import ViewJobOffer from '../components/job-offers/ViewJobOffer.jsx';
import CandidatesList from '../components/candidates/CandidatesList.jsx';
import ViewCandidatesInfo from '../components/candidates/ViewCandidatesInfo.jsx';
// import CheckoutForm from '../components/payment/CheckoutForm.jsx';
import Payment from '../components/payment/Payment.js';
import StripeProcessing from '../components/payment/StripeProcessing.js';
import PaymentSuccess from '../components/payment/PaymentSuccess.jsx';
import PaymentError from '../components/payment/PaymentError.jsx';
import PaymentsList from '../components/payment/PaymentsList.jsx';
import RecoverPassword from '../components/password/RecoverPassword.jsx';
import ResetPassword from '../components/password/ResetPassword.jsx';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                index: true,
                element: <CompanyDetails />
            },
            {
                path: "profile",
                element: <CompanyProfile />,
            },
            {
                path: "job-offers",
                element: <JobOffers />,
            },
            {
                path: "add-job",
                element: <AddJob />,
            },
            {
                path: "subscription",
                element: <Subscription />,
            },
            {
                path: "privacy-policy",
                element: <PrivacyPolicy />,
            },
            {
                path: "term-of-use",
                element: <TermOfUse />,
            },
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "view-job-offer/:id",
                element: <ViewJobOffer />,
            },
            {
                path: "candidates-list/:id",
                element: <CandidatesList />,
            },
            {
                path: "view-candidates-info/:id",
                element: <ViewCandidatesInfo />,
            },
            {
                path: "payment/:id",
                element: <Payment />,
            },
            {
                path: "payment/processing/:id",
                element: <StripeProcessing />,
            },
            {
                path: "payment/success/:id/:transactionId",
                element: <PaymentSuccess />,
            }
            ,
            {
                path: "payment/error/:jobId/:id",
                element: <PaymentError />,
            },
            {
                path: "payment-list",
                element: <PaymentsList />,
            },
            {
                path: "recover-password",
                element: <RecoverPassword />,
            },
            {
                path: "reset-password/:token",
                element: <ResetPassword />,
            }
        ],
    },
]);

export default router
import React, {useEffect, useState, useCallback} from 'react';
import './candidates.css';
import {useParams, useOutletContext} from "react-router-dom";
import apiService from "../../services/api.service";
import {IMG_URL} from '../../constants/index';
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from 'react-redux';
import debounce from 'lodash.debounce';
import {ReactComponent as SearchIcon} from "../../assets/img/icon_search.svg";
import UserNoImg from '../../assets/img/user_no_image.svg';
import {Space, Table, Button, Row, Col, Input, ConfigProvider, Flex, Image} from 'antd';
const {Column} = Table;

const CandidatesList = () => {
    let {id} = useParams();
    const [candidates, setCandidates] = useState([]);
    const navigate = useNavigate();
    const [company, messageApi] = useOutletContext();
    const categories = useSelector(state => state.launch.jobOfferCategory);

    const getWhoLikedMyJobFunc = async (paramsId, searchParams) => {
        const reqObj = {
            pagination: {
                skip: 0,
                take: 10
            },
            jobs: [Number(paramsId)],
            query: searchParams
        }

        try {
            await apiService.getWhoLikedMyJob(reqObj).then(response => {
                const resp = response.data
                setCandidates(resp);
            })
        } catch (error) {
            console.log("getWhoLikedMyJob error", error)
        }
    }

    const dateAppliedFunc = (date) => {
        const options = {day: 'numeric', month: 'short', year: 'numeric'};
        const today = new Date(date);
        return today.toLocaleDateString("en-GB", options)
    }

    const getUserImage = (src) => {
        if (src) {
            return IMG_URL + '/' + src
        } else {
            return UserNoImg
        }
    }

    const getUserProfession = (item) => {
        return categories.find(el => el.id === item.joboffercategoryId).title
    }

    const debouncedHandleSearch = useCallback(debounce((ev) => getWhoLikedMyJobFunc(id, ev), 1000), []);

    useEffect(() => {
        getWhoLikedMyJobFunc(id, '')
    }, [id])

    // useEffect(() => {
    //     console.log("candidates", candidates)
    // }, [candidates])

    // useEffect(() => {
    //     console.log("categories", categories)
    // }, [categories])

    return (
        <div className="container">
            <div className="custom-padding">
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                activeBorderColor: '#732135',
                                hoverBorderColor: '#732135',
                                colorBorder: '#aeaeae',
                                controlHeight: 38,
                                activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                            },
                            Button: {
                                primaryShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                                controlHeight: 32,
                                defaultColor: '#732135',
                                defaultBorderColor: '#732135',
                                defaultActiveBorderColor: '#732135',
                                defaultHoverBorderColor: '#732135',
                                defaultHoverBg: '#732135',
                                defaultHoverColor: '#ffffff'
                            },
                        },
                    }}
                >
                    <div className="job-information text-left">
                        <h2>Candidates List</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales <br /> sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                    </div>
                    <Row className="job-offers-search-block">
                        <Col span={8} offset={16}>
                            <div className="form-section search-form-section">
                                <Input
                                    placeholder="Search..."
                                    allowClear
                                    onChange={(e) => debouncedHandleSearch(e.target.value)}
                                    prefix={<SearchIcon />} />
                            </div>
                        </Col>
                    </Row>
                    <div className="job-table candidates-table">
                        <Table
                            scroll={{
                                x: 768,
                            }}
                            dataSource={candidates}
                            bordered={false}
                            rowKey={(data => data.id.toString())}
                        >
                            <Column
                                className='candidates-info'
                                title="Candidates"
                                dataIndex="User"
                                render={(user, {id}) => (
                                    <Flex key={id} justify='flex-start' align='center'>
                                        <Image
                                            width={50}
                                            src={getUserImage(user.photo?.path)}
                                            preview={false}
                                        />
                                        <span className='candidates-name'>
                                            {user.firstName} {user.lastName}
                                        </span>
                                    </Flex>
                                )}
                            />
                            <Column
                                className='user-profession'
                                title="Profession"
                                dataIndex="User"
                                // width={130}
                                render={(user, {id}) => (
                                    <div key={id.toString()}>
                                        {user.profile.categories.map((item) => (
                                            <p key={item.id}>{getUserProfession(item)}</p>
                                        ))}
                                    </div>
                                )}
                            />
                            <Column
                                className='job-status'
                                title="Date applied"
                                dataIndex="createdAt"
                                // width={230}
                                render={(date, {id}) => (
                                    <div key={id.toString()}>
                                        {dateAppliedFunc(date)}
                                    </div>
                                )}
                            />
                            <Column
                                className='job-action'
                                title="CV"
                                align='center'
                                width={150}
                                render={(data) => (
                                    <Space size="middle" key={data.id.toString()}>
                                        <Button
                                            className='candidates-list-btn'
                                            onClick={(event) => {
                                                event.preventDefault()
                                                event.stopPropagation()
                                                navigate("/view-candidates-info/" + data.id, {state: {likeId: data.id}});
                                            }}
                                        >
                                            View CV
                                        </Button>
                                    </Space>
                                )}
                            />
                        </Table>
                    </div>
                </ConfigProvider>
            </div>
        </div>
    )
}

export default CandidatesList
import React, {useEffect, useState, useRef} from 'react';
import apiService from "../../services/api.service";
import {
    Col,
    Row,
    Form,
    Input,
    InputNumber,
    Mentions,
    Select,
    Space,
    Modal,
    Upload,
    ConfigProvider
} from 'antd';
import {EyeInvisibleOutlined, EyeTwoTone, PlusOutlined} from '@ant-design/icons';
import {API_URL} from "../../constants";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const CompanyDetailsStepOne = ({location, setCompanyInfoForm, setLogoCompany, setCoversCompany, form}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [cityOptions, setCityOptions] = useState([]);

    const refLogo = useRef()

    const [logoImg, setLogoImg] = useState([
        // {
        //     uid: '-1',
        //     name: 'logo_company.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // }
    ])
    const [fileList, setFileList] = useState([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
    ]);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
        },
        labelAlign: 'left'
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({fileList: newFileList}) => {
        setFileList([...newFileList])
    };

    const handleLogoChange = ({fileList: logoImg}) => {
        console.log("logoImg", logoImg)
        setLogoImg(logoImg)
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleCountryChange = (event) => {
        let selectedCity = location.find(loc => loc.name.toLowerCase() === event).City
        setCityOptions(selectedCity)
    }

    const handleFormChange = (changedValues, allValues) => {
        // console.log("changedValues, allValues", changedValues, allValues)
    }

    useEffect(() => {
        setLogoCompany(refLogo, logoImg)
    }, [logoImg])

    useEffect(()=> {
        setTimeout(()=> {
            setCoversCompany([...fileList])
        }, 500)

    }, fileList)

    return (
        <ConfigProvider
            theme={{
                components: {
                    InputNumber: {
                        activeBorderColor: '#732135',
                        hoverBorderColor: '#732135',
                        controlOutline: 'rgba(115, 34, 52, 0.1);',
                        optionSelectedBg: '#732135',
                    },
                    Input: {
                        activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                        activeBorderColor: '#732135',
                        hoverBorderColor: '#732135',
                        colorBorder: '#aeaeae',
                    },
                    Select: {
                        colorBorder: '#aeaeae',
                        controlOutline: 'rgba(115, 34, 52, 0.1);',
                        optionSelectedBg: '#732135',
                        colorPrimary: '#732135',
                        colorPrimaryHover: '#732135',
                    }
                },
            }}
        >
            <Form onFieldsChange={handleFormChange} name='companyDetailsForm' form={form} {...formItemLayout} >
                <Row className='company-details-step' gutter={12}>

                    <Col xs={24} lg={12} >
                        <h3>Sign Up for free</h3>
                        <h1>Create company profile</h1>
                        <p className='company-profile-p'>Pleae sign up to your personal account if you want to use all our <br /> premium features.</p>

                        <div className="compnay-logo-upload upload-block-style">
                            <h5>Company Logo</h5>
                            <p>Click to upload user's avatar, and validate size and format of picture <br /> with beforeUpload.</p>
                            <Form.Item name='companyLogo' >
                                <Upload
                                    action={API_URL+"/company/upload/photos"}
                                    headers={apiService.authHeader()}
                                    name='photos'
                                    ref={refLogo}
                                    listType="picture-card"
                                    fileList={logoImg}
                                    onPreview={handlePreview}
                                    onChange={handleLogoChange}
                                    onRemove={(file) => {
                                        const index = logoImg.indexOf(file);
                                        const newFileList = logoImg.slice();
                                        newFileList.splice(index, 1);
                                        setLogoImg(newFileList);
                                    }}
                                    // beforeUpload={(file) => {
                                    //     setLogoImg([...logoImg, file]);
                                    //     return false;
                                    // }}
                                >
                                    {logoImg.length >= 1 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </div>

                        <div className="picture-wall-upload upload-block-style">
                            <h5>Pictures Wall</h5>
                            <p>After users upload picture, the thumbnail will be shown in list. <br /> The upload button will disappear when count meets limitation.</p>
                            <Form.Item name='companyPictures' >
                                <Upload
                                    action={API_URL+"/company/upload/photos"}
                                    listType="picture-card"
                                    fileList={fileList}
                                    name='photos'
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    multiple={true}
                                    onRemove={(file) => {
                                        const index = fileList.indexOf(file);
                                        const newFileList = fileList.slice();
                                        newFileList.splice(index, 1);
                                        setFileList(newFileList);
                                    }}
                                    // beforeUpload={(file) => {
                                    //     setFileList([...fileList, file]);
                                    //     return false;
                                    // }}
                                >
                                    {fileList.length >= 10 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </div>

                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </Col>
                    <Col xs={24} lg={{span: 11, offset: 1}}>
                        <h3 className='comp-details-head'>Company Details</h3>

                        <div className="form-section">
                            <Form.Item
                                label="Company:"
                                name="name"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Email:"
                                name="email"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Input type='email' />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder="input password"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="password2"
                                dependencies={['password']}
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    placeholder="input password"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Tax ID:"
                                name="tax_id"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Phone 1:"
                                name="phone1"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Phone 2:"
                                name="phone2"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Country:"
                                name="country"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={handleCountryChange}
                                    options={location.map((loc) => ({
                                        label: loc.name,
                                        value: loc.name.toLowerCase(),
                                    }))} />
                            </Form.Item>

                            <div className='city-zip-style'>
                                <Form.Item
                                    label="City:"
                                    name="locationId"
                                    style={{
                                        width: 'calc(100%)',
                                    }}
                                    rules={[
                                        {
                                            // required: true,
                                            message: 'Please input!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label.toLowerCase() ?? '').toLowerCase().localeCompare((optionB?.label.toLowerCase() ?? '').toLowerCase())
                                        }
                                        options={cityOptions.map((item) => ({
                                            label: item.name,
                                            value: item.id.toString(),
                                        }))} />
                                </Form.Item>

                                <Form.Item
                                    label="Zip Code:"
                                    name="zipcode"
                                    rules={[
                                        {
                                            // required: true,
                                            message: 'Please input!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>

                            <Form.Item
                                label="Street:"
                                name="street"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>

        </ConfigProvider>
    )
}

export default CompanyDetailsStepOne;

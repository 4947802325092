import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    workTimeType: [],
    jobOfferCategory: []
}

const launchSlice = createSlice({
    name: 'launchReducer',
    initialState,
    reducers: {
        setWorkTimeType(state, action) {
            state.workTimeType = action.payload;
        },
        setJobOfferCategory(state, action) {
            state.jobOfferCategory = action.payload;
        },
    },
})

export default launchSlice.reducer

export const {
    setWorkTimeType,
    setJobOfferCategory
} = launchSlice.actions


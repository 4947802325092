import React, {useState, useEffect} from 'react';
import {Button, Modal, DatePicker, Form, Input, InputNumber, Radio, Select, Checkbox, Row, Col, Space, ConfigProvider} from 'antd';
import {ReactComponent as SearchIcon} from "../../assets/img/icon_search.svg";
import apiService from "../../services/api.service";

const { TextArea } = Input;

const EditCompanyProfileModal = ({rawCompany, contactCompany, categories, about, domainActivities, profileKeyName, openEditModal, setOpenEditModal}) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [form] = Form.useForm();

    const [initalValues, setInitialValues] = useState({})

    const [customDomainActivities, setDomainActivities] = useState([])
    const [customDomainActivitiesToAdd, setDomainActivitiesToAdd] = useState([])


    const [locations, setLocations] = useState([])
    const [cityOptions, setCityOptions] = useState([]);

    const getLocationFunc = async () => {
        let resp = await apiService.getLocation();
        setLocations(resp.data['list']);
        if(rawCompany !== undefined && rawCompany.Location) {
            let countryCities = resp.data['list'].find(loc => loc.id === rawCompany.Location.Country.id).City
            setCityOptions(countryCities)
        }
    }


    const handleOk = async () => {
        let formObj = form.getFieldValue();
        if(profileKeyName === 'domainActivities' ) {
            formObj = {
                domainActivity:[
                    ...customDomainActivities,
                    ...customDomainActivitiesToAdd
                ]
            }
        }

        await apiService.editCompany(formObj)
        setModalText('Success');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpenEditModal(false);
            setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpenEditModal(false);
    };

    const onChangeActivity = (list) => {
        console.log("list", )
        let toConcat = [];
        let temp = JSON.parse(JSON.stringify(customDomainActivities));
        temp.forEach(el => {
            console.log(el)
            if(!list.includes(el.id.toString())) {
               el['_destroy'] = true;
            } else {
                if(el['_destroy'])
                    delete el['_destroy'];
            }
        })
        for(let item of list) {
            if(!temp.find(el=>el.id.toString() === item)) {
                toConcat.push({
                    joboffercategoryId: parseInt(item)
                })
            }
        }
        setDomainActivitiesToAdd(toConcat)
        setDomainActivities([
            ...temp
        ])
    };

    const onSearchFunc = (ev) => {
        let searchVal = ev.target.value.toLowerCase()
        if (categories.length > 0) {
            if (searchVal.length > 0) {
                let filteredCategories = categories.filter(item => item.description?.toLowerCase().includes(searchVal) || item.title.toLowerCase().includes(searchVal))
                setFilteredOptions(filteredCategories)
            } else {
                setFilteredOptions(categories)
            }
        }
    }
    const handleCountryChange = (event) => {
        let selectedCity = locations.find(loc => loc.name.toLowerCase() === event).City
        setCityOptions(selectedCity)
    }

    useEffect(() => {
        if (categories.length > 0) {
            setFilteredOptions(categories)
        }
    }, [categories])
    useEffect(()=>{
            if(rawCompany) {
                setInitialValues({
                    name:rawCompany.name,
                    description:rawCompany.description,
                    tax_id:rawCompany.tax_id,
                    phone1:rawCompany.phone1,
                    phone2:rawCompany.phone2,
                    housenumber:rawCompany.housenumber,
                    zipcode:rawCompany.zipcode,
                    street:rawCompany.street,
                    year:rawCompany.year,
                    employers:rawCompany.employers,
                    locationId:rawCompany.locationId
                })
                getLocationFunc()
            }
    }, [rawCompany])
    useEffect(() => {

        setDomainActivities(domainActivities)
    }, [domainActivities]);


    return (
        <ConfigProvider
            theme={{
                components: {
                    InputNumber: {
                        activeBorderColor: '#732135',
                        hoverBorderColor: '#732135',
                        controlOutline: 'rgba(115, 34, 52, 0.1);',
                        optionSelectedBg: '#732135',
                    },
                    Input: {
                        activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                        activeBorderColor: '#732135',
                        hoverBorderColor: '#732135',
                        colorBorder: '#aeaeae',
                    },
                    Select: {
                        colorBorder: '#aeaeae',
                        controlOutline: 'rgba(115, 34, 52, 0.1);',
                        optionSelectedBg: '#732135',
                        colorPrimary: '#732135',
                        colorPrimaryHover: '#732135',
                    }
                },
            }}
        >
            <Modal
                className='edit-company-modal'
                title="Edit"
                okText='Save'
                open={openEditModal}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                {Object.keys(initalValues).length && (
                    <Form
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                        labelAlign='left'
                        layout="horizontal"
                        initialValues={{
                            ...initalValues
                        }}
                    >
                        {profileKeyName === 'companyInfo' && (
                            <h3>Company Information</h3>
                        )}


                        {profileKeyName === 'aboutCompany' && (
                            <Form.Item
                                key={new Date()}
                                name='description'
                                className='aboutCommpnay-style'
                            >
                                <TextArea rows={4} placeholder="About Us..." maxLength={3000} />
                            </Form.Item>
                        )}

                        {profileKeyName === 'companyContact' && (
                            <>
                                <Form.Item
                                    key={'name'}
                                    label={'Name'}
                                    name={'name'}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    key={'tax_id'}
                                    label={'Tax ID'}
                                    name={'tax_id'}
                                >
                                    <Input  />
                                </Form.Item>

                                <Form.Item
                                    key={'phone1'}
                                    label={'Phone 1'}
                                    name={'phone1'}
                                >
                                    <Input  />
                                </Form.Item>

                                <Form.Item
                                    key={'phone2'}
                                    label={'Phone 2'}
                                    name={'phone2'}
                                >
                                    <Input  />
                                </Form.Item>

                                <Form.Item
                                    key={'country'}
                                    label={'Country'}
                                    name={'country'}
                                >
                                    <Select
                                        disabled
                                        defaultValue={{
                                            label: rawCompany.Location.Country.name,
                                            value:rawCompany.Location.Country.name.toLowerCase()
                                        }}
                                        options={locations.map((loc) => ({
                                            label: loc.name,
                                            value: loc.name.toLowerCase(),
                                        }))} />

                                </Form.Item>

                                <Form.Item
                                    key={'locationId'}
                                    label={'City'}
                                    name={'locationId'}
                                >
                                    {/*Span-ul nu-l sterge! E magie!*/}
                                    <span style={{display: 'none'}}>{rawCompany.locationId}</span>
                                    {/*Span-ul nu-l sterge! E magie!*/}

                                    {cityOptions.length && (<Select
                                        disabled
                                        showSearch
                                        allowClear
                                        defaultValue={rawCompany.locationId.toString()}
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label.toLowerCase() ?? '').toLowerCase().localeCompare((optionB?.label.toLowerCase() ?? '').toLowerCase())
                                        }
                                        options={cityOptions.map((item) => ({
                                            label: item.name,
                                            value: item.id.toString(),
                                        }))} />
                                    )}

                                </Form.Item>
                                <Form.Item
                                    key={'street'}
                                    label={'Street'}
                                    name={'street'}
                                >
                                    <Input  />
                                </Form.Item>

                                <Form.Item
                                    key={'zipcode'}
                                    label={'Zip Code'}
                                    name={'zipcode'}
                                >
                                    <Input  />
                                </Form.Item>

                            </>
                        )}

                        {profileKeyName === 'domainActivities' && (
                            <>
                                <div className="form-section search-form-section">
                                    <Input placeholder="Search..." allowClear onChange={onSearchFunc} prefix={<SearchIcon />} />
                                </div>
                                <h3>Choose domain of activity:</h3>
                                <div className="company-activity-list">

                                    <Checkbox.Group onChange={onChangeActivity} defaultValue={domainActivities.map(item => item.id.toString())}>
                                        <Row>
                                            <Col span={24}>
                                                {filteredOptions.map((item) => (
                                                    <Checkbox key={item.id.toString()} value={item.id.toString()}>
                                                        <Space direction="vertical" gutter={12}>
                                                            <div className="activity-name">
                                                                {item.title}
                                                            </div>
                                                            <div className="acivity-descryption">
                                                                {item.description}
                                                            </div>
                                                        </Space>
                                                    </Checkbox>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </div>
                            </>
                        )}
                    </Form>
                )}

            </Modal>
        </ConfigProvider>
    );
};
export default EditCompanyProfileModal;

import React, {useEffect} from 'react';
import './login.css';
import {useSelector, useDispatch} from 'react-redux';
import {setIsModalOpen} from '../../slices/login';
import {Modal} from 'antd';
import Login from './Login';

const LoginModal = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(state => state.login.isModalOpen);

    const handleOk = () => {
        dispatch(setIsModalOpen(false))
    };

    const handleCancel = () => {
        dispatch(setIsModalOpen(false))
    };

    useEffect(() => {
        dispatch(setIsModalOpen(false))
    }, [])

    return (
        <Modal width={1140} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} afterClose={() => dispatch(setIsModalOpen(false))} className='login-modal-block' footer={null}>
            <Login />
        </Modal>
    )
}

export default LoginModal
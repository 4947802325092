import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    isModalOpen: false,
}

const loginSlice = createSlice({
    name: 'loginReducer',
    initialState,
    reducers: {
        setIsModalOpen(state, action) {
            state.isModalOpen = action.payload;
        },
    },
})

export default loginSlice.reducer

export const {setIsModalOpen} = loginSlice.actions

// export const selectPostById = (state, postId) =>
//     state.posts.posts.find((post) => post.id === postId)


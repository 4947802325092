import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
    Elements,
} from '@stripe/react-stripe-js';
import apiService from "../../services/api.service";
import Loader from '../loader/Loader';
import {useNavigate, useOutletContext, useLocation, useParams} from "react-router-dom";
import StripeCheckoutForm from "./StripeCheckoutForm";
import {useSelector} from 'react-redux'

const stripePromise = loadStripe('pk_test_FQRkJ2sDhk0hjqOfSpsmoMcc');

const Payment = () => {
    const company = useOutletContext();
    const [clientSecret, setClientSecret] = useState("");
    const [companySubscription, setCompanySubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getClientSecretStripeFunc = async () => {
        const priceMatch = companySubscription?.price_match.toFixed(2);

        const intent = {
            amount: priceMatch,
            currency: "eur"
        }

        try {
            await apiService.getClientSecretStripe(intent).then(response => {
                // console.log('stripe response', response)
                setClientSecret(response.data.clientSecret)
                setIsLoading(false)
            })
        } catch (error) {
            console.log("getCompanyFunc error", error)
        }
    }

    let options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#732135',
                colorBackground: '#ffffff',
                colorText: '#2a2a35',
                colorDanger: '#df1b41',
                spacingUnit: '2px',
                borderRadius: '4px',
            },
            rules: {
                '.Input': {
                    lineHeight: '40px',
                    borderColor: '#AEAEAD'
                },
                '.Tab': {
                    lineHeight: '22px',
                    borderColor: '#AEAEAD'
                },
                '.Label': {
                    margin: '10px 0 5px',
                },
            }
        },

    };

    useEffect(() => {
        if (!company) return;
        const activeSubscription = company[0].CompanySubscription?.find(item => item.active)
        setCompanySubscription(activeSubscription?.subscription)
    }, [company])

    useEffect(() => {
        setIsLoading(true)
        if (!companySubscription) return;
        getClientSecretStripeFunc();
    }, [companySubscription])

    return (
        <>
            {isLoading
                ? <Loader loading={isLoading} />
                : (
                    <>
                        {options.clientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                                <StripeCheckoutForm companySubscription={companySubscription} />
                            </Elements>
                        )}
                    </>
                )
            }

        </>
    )
};
export default Payment;

import React, {useState, useEffect} from 'react';
import apiService from "../../services/api.service";
import {Space, Table, Tag, Button, Row, Col, Popover, Input, ConfigProvider, Popconfirm, Flex} from 'antd';
import {ReactComponent as SearchIcon} from "../../assets/img/icon_search.svg";
import './job-offers.css';
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {setIsJobOfferEdit} from '../../slices/job';
import {ReactComponent as JobIcon} from '../../assets/img/icon_jobs.svg';
import {ReactComponent as CandidatesImg} from '../../assets/img/icon_candidatetes.svg';
import {PlayCircleOutlined, PauseCircleOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
const {Column} = Table;

export const JobOffers = () => {
    const [loadings, setLoadings] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [jobOffers, setJobOffers] = useState([]);
    const navigate = useNavigate();
    const [company, messageApi] = useOutletContext();
    const dispatch = useDispatch();

    const confirmDeleteJob = async (event, id) => {
        event.preventDefault();
        event.stopPropagation();

        const jobIdObj = {
            jobId: id
        }

        await apiService.onDeleteJob(jobIdObj).then(response => {
            console.log("response", response)
            setTimeout(() => {
                getJobOffersFunc()
            }, 500);
        })

        messageApi.open({
            className: 'succes-delete-job-icon',
            duration: 3,
            type: 'success',
            content: 'Click on Yes',
        });

    };

    const cancel = (e) => {
        console.log(e);
        e.preventDefault()
        e.stopPropagation()
        messageApi.open({
            className: 'error-delete-job-icon',
            duration: 3,
            type: 'error',
            content: 'Click on No',
        });
    };

    const handleJobStatus = async (id) => {
        const jobIdObj = {
            jobId: id
        }

        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[id] = true;
            return newLoadings;
        });

        await apiService.setJobStatus(jobIdObj).then(response => {
            setTimeout(() => {
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[id] = false;
                    return newLoadings;
                });
                getJobOffersFunc()
            }, 1000);
        })
    };

    const actionItems = (data) => {

        return (
            <div className='job-action-list-btn'>
                <Link
                    className='job-action-list-item'
                    to={'/view-job-offer/' + data.id}
                    onClick={(event) => {
                        event.stopPropagation()
                        dispatch(setIsJobOfferEdit(true))
                    }}
                    state={{data: data}}
                >
                    <EditOutlined />
                </Link>
                <Popconfirm
                    placement="topRight"
                    className='job-popconfirm'
                    title="Delete the Job"
                    description="Are you sure to delete this Job?"
                    onConfirm={(event) => confirmDeleteJob(event, data.id)}
                    onCancel={cancel}
                    onPopupClick={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    {/* <Button danger>Delete</Button> */}
                    <span className='job-action-list-item'
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            console.log('DeleteOutlined')
                        }}>
                        <DeleteOutlined />
                    </span>
                </Popconfirm>
            </div>
        )
    }

    const getJobOffersFunc = async () => {
        const reqObj = {
            pagination: {
                skip: 0,
                take: 100
            }
        }

        try {
            await apiService.getJobOffers(reqObj).then(response => {
                const resp = response.data.map((item, index) => {
                    item.status = item.status === 1 ? ['active'] : ['paused']
                    return item;
                })

                setJobOffers(resp);
            })
        } catch (error) {
            console.log("getCompanyFunc error", error)
        }
    }

    const jobStatus = (status, id) => {
        let color = 'green';
        if (status === 'paused') {
            color = 'orange';
        }
        return (
            <Tag color={color} key={id.toString()}>
                {status}
            </Tag>
        );
    }

    const onSearchFunc = (ev) => {
        let searchVal = ev.target.value.toLowerCase()
        if (jobOffers.length > 0) {
            if (searchVal.length > 0) {
                let filteredCategories = jobOffers.filter(item => item.title?.toLowerCase().includes(searchVal))
                setFilteredOptions(filteredCategories)
            } else {
                setFilteredOptions(jobOffers)
            }
        }
    }

    const onRowClick = (id) => {
        navigate('/view-job-offer/' + id)
    }

    useEffect(() => {
        getJobOffersFunc()
        dispatch(setIsJobOfferEdit(false))
    }, [])

    useEffect(() => {
        console.log("jobOffers", jobOffers)
        setFilteredOptions(jobOffers)
    }, [jobOffers])

    // useEffect(() => {
    //     console.log("filteredOptions", filteredOptions)
    // }, [filteredOptions])

    return (
        <div className="container">
            <div className="custom-padding">
                <div className="job-information text-left">
                    <h2>Job Offers</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales <br /> sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                </div>
                <Row className="job-offers-search-block">
                    <Col span={8} offset={16}>
                        <div className="form-section search-form-section">
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Input: {
                                            activeBorderColor: '#732135',
                                            hoverBorderColor: '#732135',
                                            colorBorder: '#aeaeae',
                                            controlHeight: 38,
                                            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                                        },
                                    },
                                }}
                            >
                                <Input placeholder="Search..." allowClear onChange={onSearchFunc} prefix={<SearchIcon />} />
                            </ConfigProvider>
                        </div>
                    </Col>
                </Row>
                <div className="job-table">
                    <Table
                        dataSource={filteredOptions}
                        bordered={false}
                        rowKey={(data => data.id.toString())}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => onRowClick(record.id),
                            };
                        }}
                    >
                        <Column
                            className='job-position-info'
                            title="Job position"
                            dataIndex="title"
                            render={(title, {id}) => (
                                <div key={id.toString()}>
                                    <p><JobIcon /><span className='job-offers-name'>{title}</span></p>
                                </div>
                            )}
                        />
                        <Column
                            className='job-canditates'
                            title="Candidates"
                            dataIndex="likes"
                            width={130}
                            render={(status, {likes, id}) => (
                                <div key={id.toString()}>
                                    <Link
                                        className='job-action-canditates-list'
                                        to={'/candidates-list/' + id}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                        }}
                                    // target='_blank'
                                    // state={{data: data}}
                                    >
                                        <Flex gap="small" horizontal={'true'} justify={'flex-start'} align={'center'}>
                                            <CandidatesImg />
                                            <span>{likes}</span>
                                        </Flex>
                                    </Link>
                                </div>
                            )}
                        />
                        <Column
                            className='job-status'
                            title="Job status"
                            dataIndex="status"
                            width={230}
                            render={(status, {id}) => (
                                <div key={id.toString()}>
                                    {jobStatus(status[0], id)}
                                </div>
                            )}
                        />
                        <Column
                            className='job-action'
                            title="Action"
                            width={100}
                            render={(data) => (
                                <Space size="middle" key={data.id.toString()}>
                                    <Button
                                        type="text"
                                        icon={data.status[0] === 'paused' ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
                                        loading={loadings[data.id]}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            handleJobStatus(data.id)
                                        }}
                                    />
                                    <Popover
                                        getPopupContainer={(container) => container}
                                        overlayClassName='job-action-dropdown-overlay'
                                        arrow={false}
                                        content={() => actionItems(data)}
                                        trigger="click"
                                        placement='left'
                                    >
                                        <Button
                                            type="text"
                                            onClick={(event) => {
                                                event.preventDefault()
                                                event.stopPropagation()
                                            }}
                                            icon={<EllipsisOutlined />}
                                        />
                                    </Popover>
                                </Space>
                            )}
                        />
                    </Table>
                </div>
            </div>
        </div>
    )
}

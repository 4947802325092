import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate, useOutletContext} from "react-router-dom";
import './password.css';
import '../login-form/login.css';
import apiService from "../../services/api.service";
import {useSelector, useDispatch} from 'react-redux';
import {setIsModalOpen} from '../../slices/login';
import LoginImg from '../../assets/img/image_login.png';
import {Layout, Flex, Steps, Col, Row, Button, Modal, Image, Form, Checkbox, Input, ConfigProvider, Space} from 'antd';
import {RightOutlined} from '@ant-design/icons';

const ReserPassword = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(state => state.login.isModalOpen);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    let {token} = useParams();
    const [company, messageApi] = useOutletContext();

    const [disabledButton, setDisabledButton] = useState(false)
    const [newPass, setNewPass] = useState(null)
    const [successResetPass, setSuccessResetPass] = useState(false)

    const showModal = () => {
        dispatch(setIsModalOpen(true))
    };

    const onResetPasswordFunc = async (values) => {
        console.log('values', values)
        console.log('newPass', newPass)
        try {
            await apiService.onResetPassword({
                token: token,
                password: newPass
            }).then(response => {
                console.log("onRecoverPasswordFunc response", response)
                if (response.data.success === true) {
                    setDisabledButton(true)
                    setSuccessResetPass(true)
                    messageApi.open({
                        className: 'open-message-style',
                        duration: 10,
                        type: 'success',
                        content: 'Congratulations! You have set a new password!',
                    });
                }
            });
        } catch (error) {
            console.log("Login error", error)
            messageApi.open({
                className: 'open-message-style',
                duration: 5,
                type: 'error',
                content: error.response.data.message,
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.resetFields()
    }, [isModalOpen])

    useEffect(() => {
        console.log('token', token)
    }, [token])

    return (
        <div className='login-modal-block container' >
            <ConfigProvider
                theme={{
                    components: {
                        Input: {
                            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                            activeBorderColor: '#732135',
                            hoverBorderColor: '#732135',
                            colorBorder: '#aeaeae',
                        },
                        Checkbox: {
                            colorPrimary: '#732135',
                            colorPrimaryHover: '#732135',
                            colorBorder: '#aeaeae',
                        }
                    },
                }}
            >
                <Row>
                    <Col xs={24} lg={12} className='hello-block'>
                        <Image
                            preview={false}
                            src={LoginImg}
                        />
                        <h3 className='text-center'>Very good works are waiting for you.</h3>
                        <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </Col>
                    <Col xs={24} lg={12} className='login-block'>
                        {!successResetPass ? (
                            <>
                                <h2>Reset Password</h2>
                                <p>Email verification is done. Please choose another password</p>
                                <Form
                                    disabled={disabledButton}
                                    form={form}
                                    name="loginForm"
                                    layout="vertical"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onResetPasswordFunc}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >

                                    <Form.Item
                                        label="New password"
                                        name="new_password"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    {/* Field */}
                                    <Form.Item
                                        label="Confirm new password"
                                        name="repeat_new_password"
                                        dependencies={['new_password']}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('new_password') === value) {
                                                        setNewPass(value)
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item className='sign-in-style'>
                                        <div className="custom-btn-block">
                                            <Button htmlType="submit" block className='w-100'>
                                                Reset password
                                            </Button>
                                        </div>
                                    </Form.Item>
                                    <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                        <p className='have-account'>Don’t you have an account?</p>
                                        <Link to='/' className='create-account-link'>Create an account</Link>
                                    </Flex>

                                    <div className='company-details-login-block'>
                                        <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                            <p className='have-account'>Go back to</p>
                                            <Button type="link" className='recover-login-btn' onClick={showModal}>Log in</Button>
                                        </Flex>
                                    </div>
                                </Form>
                                <div className="login-copyright-block">
                                    <p className='text-center'>Copyright 2024 JOB123 LTD.</p>
                                    <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                        <Link to='/privacy-policy' target='_blank' >Privacy Policy</Link>
                                        <Link to='/term-of-use' target='_blank' >Term of Use</Link>
                                    </Flex>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2>Password is changed!</h2>
                                <p>Your password is successfully changed. Please sign in to your account and start a new project.</p>

                                <div className='sign-in-style'>
                                    <div className="custom-btn-block">
                                        <Button block className='w-100 reset-btn-signin-style' href='/login' icon={<RightOutlined />} iconPosition='start'>
                                            Sign in
                                        </Button>
                                    </div>
                                </div>

                                <div className='company-details-login-block'>
                                    <Flex gap="middle" align="center" justify="center" horizontal='true'>
                                        <p>Didn't receive an email? <Button type="link" className='recover-login-btn' onClick={() => navigate('/recover-password')}>Try again</Button></p>
                                    </Flex>
                                </div>
                            </>
                        )}

                    </Col>
                </Row>
            </ConfigProvider>
        </div>
    )
}

export default ReserPassword
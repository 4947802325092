import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useLocation} from "react-router-dom";
import '../add-job/add-job.css';
import {errorCodeList} from '../../constants/errorCode';
import {DRIVING_LICENCE, IMG_URL, API_URL} from '../../constants/index';
import apiService from "../../services/api.service";
import {useSelector, useDispatch} from 'react-redux';
import {setIsJobOfferEdit} from '../../slices/job';
import {Form, Upload, Modal, Select, Button, Row, Col, Popover, Popconfirm, Input, ConfigProvider, Image, Flex} from 'antd';
import {PlusOutlined, MinusOutlined, ArrowRightOutlined, ExclamationCircleFilled, DeleteOutlined} from '@ant-design/icons';
import {ReactComponent as PublishJobIcon} from "../../assets/img/icon_new_outline.svg";
const {TextArea} = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const EditJobOffer = ({jobOffer, locations}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cityOptions, setCityOptions] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [form] = Form.useForm();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [company, messageApi] = useOutletContext();
    const [companySubscription, setCompanySubscription] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [offerJobObj, setOfferJobObj] = useState([]);
    const [offerJobLicense, setOfferJobLicense] = useState([]);
    const [speakLang, setSpeakLang] = useState([]);
    const [removedImg, setRemovedImg] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [open, setOpen] = useState(false);
    let location = useLocation();

    const [languageLevel, setLanguageLevel] = useState(['A1', 'A2', 'B1', 'B2', 'C1', 'C2']);

    const workType = useSelector(item => item.launch.workTimeType);
    const categories = useSelector(item => item.launch.jobOfferCategory);

    const onPublishJob = async () => {
        console.log("onPublishJob form", form.getFieldValue())
        const {
            jobPosition,
            minSalary,
            maxSalary,
            worktimetype,
            country,
            city,
            zipCode,
            street,
            speaklanguage,
            drivinglicense,
            yourTasks,
            qualification,
            categories,
            weOffer
        } = form.getFieldValue();

        console.log("drivinglicense", drivinglicense)

        const destroyLang = speakLang.filter(item => item._destroy === true)
        const newImg = fileList.filter(item => !item.id)
        const filterFileList = fileList.filter(item => item.id)

        const newImgArr = newImg.map(item => {
            const items = item.response.map(elem => {
                return {
                    photoId: elem.id,
                    jobofferId: jobOffer.id
                }
            })

            return items[0]
        })

        let publishJobForm = {}

        if (company) {
            publishJobForm = {
                id: jobOffer.id,
                companyId: company.id,
                locationId: Number(city) || jobOffer.Location.id || null,
                title: jobPosition,
                zip: zipCode,
                street: street,
                tasks: yourTasks,
                qualification: qualification,
                worktimetype: offerJobObj,
                we_offer: weOffer,
                speaklanguage: [...destroyLang, ...speaklanguage],
                drivinglicense: offerJobLicense,
                joboffercategoryId: categories,
                // photos: fileList.map(item => {return {id: item.response[0].id}}),
                photos: [...filterFileList, ...removedImg, ...newImgArr],
                salary_min: Number(minSalary) || null,
                salary_max: Number(maxSalary) || null,
                description: 'description company.id => ' + company.id
            }
        }
        console.log("publishJobForm", publishJobForm)

        await apiService.editJob(publishJobForm).then(response => {
            console.log("response editJob", response)
            messageApi.open({
                className: 'open-message-style',
                duration: 5,
                type: 'success',
                content: 'You have successfully edited job',
            });
            // navigate("/job-offers");
        }).catch(error => console.log("editJob error", error))
    }

    const handleFormChange = (changedValues, allValues) => {
        const formValues = allValues.map(item => item.name)
        // console.log("formValues", formValues)
    }

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
        },
        labelAlign: 'left'
    };

    const handleCountryChange = (event) => {
        let selectedCity = locations.find(loc => loc.id === event).City
        setCityOptions(selectedCity)
    }

    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleCancel = () => setPreviewOpen(false);

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    // useEffect(() => {
    //     console.log("companySubscription", companySubscription)
    // }, [companySubscription])

    const selectedWorkTimeType = (data) => {
        return workType.filter(item => data && data.find(el => el.worktimetypeId === item.id))
    };

    const selectedDrivingLicense = (data) => DRIVING_LICENCE.filter(item => {
        if (data) {
            return data.find(elem => {
                if (elem.category === item.label) {
                    return {
                        ...elem,
                        label: item.label,
                        value: item.value
                    }
                }
            })
        }
        return data

    })

    const getSelectedCityOptions = (data) => {
        let selectedCityArr = locations.find(loc => loc.id === data?.countryId).City
        const city = selectedCityArr.map(item => {
            return {
                ...item,
                label: item.name,
                value: item.id
            }
        })

        setCityOptions(city)
    }

    const onDeselectJobTypeFunc = (jobOfferObj, dataId, idx) => {
        if (offerJobObj.length > 0) {
            jobOfferObj = [...offerJobObj]
        } else {
            jobOfferObj = [...jobOfferObj]
        }

        const modifiedObj = jobOfferObj.filter((item) => {
            if (item.id && dataId === item.worktimetypeId) {
                item._destroy = true
            } else if (!item.id && item.worktimetypeId === idx.value) {
                item = null
            }
            return item
        })

        jobOfferObj = modifiedObj;
        setOfferJobObj(jobOfferObj)
    }

    const onSelectJobTypeFunc = (jobOfferObj, dataId) => {
        if (offerJobObj.length > 0) {
            jobOfferObj = [...offerJobObj]
        } else {
            jobOfferObj = [...jobOfferObj]
        }

        const modifiedObj = jobOfferObj.map(item => {
            if (dataId === item.worktimetypeId && item._destroy) {
                delete item._destroy
            }
            return item
        })

        if (modifiedObj.find(elem => elem.worktimetypeId === dataId)) {
            jobOfferObj = [...modifiedObj]
        } else {
            jobOfferObj = [...modifiedObj, {worktimetypeId: dataId}]
        }

        setOfferJobObj(jobOfferObj)
    }

    const onDeselectDrivingLincenseFunc = (jobOfferObj, dataId, idx) => {
        if (offerJobLicense.length > 0) {
            jobOfferObj = [...offerJobLicense]
        } else {
            jobOfferObj = [...jobOfferObj]
        }

        const modifiedObj = jobOfferObj.filter((item) => {
            if (item.id && dataId === item.value) {
                item._destroy = true
            } else if (!item.id && item.category === idx.label) {
                item = null
            }
            return item
        })

        jobOfferObj = modifiedObj;
        setOfferJobLicense(jobOfferObj)
    }

    const onSelectDrivingLincenseFunc = (jobOfferObj, dataId) => {
        if (offerJobLicense.length > 0) {
            jobOfferObj = [...offerJobLicense]
        } else {
            jobOfferObj = [...jobOfferObj]
        }

        const modifiedObj = jobOfferObj.map(item => {
            if (dataId === item.value && item._destroy) {
                delete item._destroy
            }
            return item
        })

        const cat = DRIVING_LICENCE.find(el => dataId === el.value).label

        const category = {
            category: cat
        }

        if (!jobOfferObj.find(el => el.category === cat)) {
            jobOfferObj.splice(modifiedObj.length, 0, category)
        }

        setOfferJobLicense(jobOfferObj)
    }

    const onRemoveSpeakLangFunc = (field, idx) => {
        if (speakLang.length > 0) {
            const lang = speakLang.map((item, index) => {
                if (index === field.key) {
                    item._destroy = true
                }
                return item
            })
            setSpeakLang(lang)
        }
    }

    const onRemoveImageFunc = (event) => {
        event._destroy = true
        setRemovedImg([...removedImg, event])
        setOpen(true)
        // return false
    }

    const confirmFunc = () => {
        setOpenConfirm(false);
        onPublishJob()
        // message.success('Next step.');
    };

    const cancelFunc = () => {
        setOpenConfirm(false);
        // message.error('Click on cancel.');
        messageApi.open({
            className: 'open-message-style',
            duration: 5,
            type: 'error',
            content: 'Click on cancel.',
        });
    };

    const handleOpenChange = (newOpen) => {
        if (!newOpen) {
            setOpenConfirm(newOpen);
            return;
        }
        setOpenConfirm(newOpen);
    };

    const cancelEditJob = () => {
        // delete jobOffer.dataTrue
        // setEditJobOffer(false)
        dispatch(setIsJobOfferEdit(false))
        console.log("jobOffer ggg", jobOffer)
    }

    useEffect(() => {
        // console.log("company www", company);
        if (company) {
            const activeSubscription = company?.CompanySubscription?.find(item => item.active)
            setCompanySubscription(activeSubscription?.subscription)
        }
    }, [company])

    useEffect(() => {
        if (!jobOffer) return
        getSelectedCityOptions(jobOffer?.Location)

        const photoArr = jobOffer.Photos.map(item => {
            return {
                ...item,
                url: IMG_URL + '/' + item.photo.path,
            }
        })
        setFileList(photoArr)
        setOfferJobObj(jobOffer.WorkTimeType)
        setOfferJobLicense(jobOffer.DrivingLicense)
        setSpeakLang(jobOffer.SpeakLanguages)

        console.log("EditJobOffer jobOffer", jobOffer)
    }, [jobOffer])

    // useEffect(() => {
    //     console.log("state state", state)
    //     // setDataState(state.data)
    // }, [state])

    useEffect(() => {
        window.onbeforeunload = () => {
            dispatch(setIsJobOfferEdit(false))
        };
    }, []);

    return (
        <div className="container">
            <Row className="job-offers-search-block">
                <Col xs={24} md={{span: 16, offset: 8}} lg={{span: 14, offset: 10}} xl={{span: 12, offset: 12}}>
                    <div className="publish-job-section">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Button: {
                                        lineWidth: 2,
                                        defaultBorderColor: '#732135',
                                        defaultActiveBorderColor: '#732135',
                                        controlHeight: 40,
                                        defaultHoverBorderColor: '#732135',
                                        defaultShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                                        primaryShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                                        colorPrimary: '#732135',
                                    },
                                },
                            }}
                        >
                            <Flex gap="small" >
                                <Button className='cancel-job-btn publish-job-btn' onClick={cancelEditJob} block >Cancel</Button>
                                <Popconfirm
                                    title="Save Job Info"
                                    description="Are you sure to save this job?"
                                    open={openConfirm}
                                    onOpenChange={handleOpenChange}
                                    onConfirm={confirmFunc}
                                    onCancel={cancelFunc}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" className="publish-job-btn save-job-btn" icon={<PublishJobIcon />} block >Save Job Info</Button>
                                </Popconfirm>
                            </Flex>
                        </ConfigProvider>
                    </div>
                </Col>
            </Row>
            <div className="subscription-special-block position-relative">
                <div className="subscription-true-block">
                    <ConfigProvider
                        theme={{
                            components: {
                                InputNumber: {
                                    activeBorderColor: '#732135',
                                    hoverBorderColor: '#732135',
                                    controlOutline: 'rgba(115, 34, 52, 0.1);',
                                    optionSelectedBg: '#732135',
                                },
                                Input: {
                                    activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                                    activeBorderColor: '#732135',
                                    hoverBorderColor: '#732135',
                                    colorBorder: '#aeaeae',
                                },
                                Select: {
                                    colorBorder: '#aeaeae',
                                    controlOutline: 'rgba(115, 34, 52, 0.1);',
                                    optionSelectedBg: '#732135',
                                    colorPrimary: '#732135',
                                    colorPrimaryHover: '#732135',
                                }
                            },
                        }}
                    >
                        <Form
                            onFieldsChange={handleFormChange}
                            name='companyDetailsForm'
                            form={form} {...formItemLayout}
                            initialValues={{
                                jobPosition: jobOffer.title,
                                minSalary: jobOffer.salary_min,
                                maxSalary: jobOffer.salary_max,
                                worktimetype: selectedWorkTimeType(jobOffer.WorkTimeType),
                                zipCode: jobOffer.zip,
                                street: jobOffer.street,
                                drivinglicense: selectedDrivingLicense(jobOffer.DrivingLicense),
                                yourTasks: jobOffer.tasks,
                                qualification: jobOffer.qualification,
                                weOffer: jobOffer.we_offer,
                                speaklanguage: jobOffer.SpeakLanguages.length > 0 ? jobOffer.SpeakLanguages : [{}],
                                country: jobOffer.Location,
                                city: jobOffer.Location.city,
                                categories: jobOffer.JobOfferCategory.id
                            }}
                        >
                            <Row className='company-details-step add-job-block' gutter={12}>
                                <Col xs={24} lg={12}>
                                    <div className="edit-block">
                                        <h2>Job Position</h2>
                                    </div>
                                    <Form.Item
                                        className="max-symbol-label"
                                        label={`Max ${companySubscription?.symbols} symbols`}
                                        name="jobPosition"
                                    >
                                        <TextArea showCount maxLength={companySubscription?.symbols} rows={3} />
                                    </Form.Item>

                                    <div className="edit-block">
                                        <h2>Job Details</h2>
                                    </div>

                                    <div className='city-zip-style'>
                                        <Form.Item
                                            label="Expected Salary:"
                                            name="minSalary"
                                            style={{
                                                width: 'calc(50%)',
                                            }}
                                        >
                                            <Input placeholder='Min' />
                                        </Form.Item>

                                        <Form.Item
                                            label=' '
                                            name="maxSalary"
                                            style={{
                                                width: 'calc(50%)',
                                            }}
                                        >
                                            <Input placeholder='Max' />
                                        </Form.Item>

                                        <Form.Item
                                            label="Job type:"
                                            name="worktimetype"
                                            style={{
                                                width: '180px',
                                            }}
                                        >
                                            <Select
                                                popupClassName="custom-multiple-type-select"
                                                placeholder='Select Item...'
                                                mode='multiple'
                                                maxTagCount='responsive'
                                                options={workType}
                                                onDeselect={(dataId, idx) => onDeselectJobTypeFunc(jobOffer.WorkTimeType, dataId, idx)}
                                                onSelect={(dataId) => onSelectJobTypeFunc(jobOffer.WorkTimeType, dataId)}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className='city-zip-style'>
                                        <Form.Item
                                            label="Country:"
                                            name="country"
                                            style={{
                                                width: 'calc(50%)',
                                            }}
                                        >
                                            <Select
                                                onChange={handleCountryChange}
                                                options={locations} />
                                        </Form.Item>

                                        <Form.Item
                                            label="City:"
                                            name="city"
                                            style={{
                                                width: 'calc(50%)',
                                            }}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label.toLowerCase() ?? '').toLowerCase().localeCompare((optionB?.label.toLowerCase() ?? '').toLowerCase())
                                                }
                                                options={cityOptions.map((item) => ({
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                }))} />
                                        </Form.Item>

                                        <Form.Item
                                            label="Zip Code:"
                                            name="zipCode"
                                            style={{
                                                width: '180px',
                                            }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    <Form.Item
                                        label="Street:"
                                        name="street"
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.List name="speaklanguage">
                                        {(fields, {add, remove}) => (
                                            <>
                                                {fields.map((field, idx) => (
                                                    <div className='city-zip-style' key={field.key}>
                                                        <Form.Item
                                                            label={idx === 0 && "Spoken languages:"}
                                                            name={[field.name, 'language']}
                                                            style={{
                                                                width: 'calc(50%)',
                                                            }}
                                                        >
                                                            <Input placeholder="English" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={idx === 0 && "Level:"}
                                                            name={[field.name, 'level']}
                                                            style={{
                                                                width: 'calc(50%)',
                                                            }}
                                                        >
                                                            <Select
                                                                placeholder="A1"
                                                                options={languageLevel.map((item, index) => ({
                                                                    label: item,
                                                                    value: item,
                                                                }))}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            className="lang-btn-style"
                                                            label={idx === 0 && " "}
                                                            name={[field.name, 'button']}
                                                            style={{
                                                                width: '100px',
                                                            }}
                                                        >
                                                            {idx === 0 && (<Button shape="circle" onClick={() => add()} icon={<PlusOutlined />} />)}
                                                            {idx > 0 && (
                                                                <Button shape="circle"
                                                                    onClick={() => {
                                                                        onRemoveSpeakLangFunc(field, idx)
                                                                        remove(field.name)
                                                                    }}
                                                                    icon={<MinusOutlined />}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>

                                    <Form.Item
                                        label="Driving license:"
                                        name="drivinglicense"
                                        style={{
                                            width: '205px',
                                        }}
                                    >
                                        <Select
                                            popupClassName="custom-multiple-type-select"
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            placeholder='B'
                                            options={DRIVING_LICENCE}
                                            onDeselect={(dataId, idx) => onDeselectDrivingLincenseFunc(jobOffer.DrivingLicense, dataId, idx)}
                                            onSelect={(dataId) => onSelectDrivingLincenseFunc(jobOffer.DrivingLicense, dataId)}
                                        />
                                    </Form.Item>

                                </Col>

                                <Col xs={24} lg={{span: 11, offset: 1}} >
                                    <div className="form-section">
                                        <div className="edit-block">
                                            <h2>Your Tasks</h2>
                                        </div>
                                        <Form.Item
                                            className="max-symbol-label"
                                            label={`Max ${companySubscription?.symbols} symbols`}
                                            name="yourTasks"
                                        >
                                            <TextArea disabled={!companySubscription?.tasks} showCount maxLength={companySubscription?.symbols} rows={3} />
                                        </Form.Item>

                                        <div className="edit-block">
                                            <h2>Our requirements (Qualification)</h2>
                                        </div>
                                        <Form.Item
                                            className="max-symbol-label"
                                            label={`Max ${companySubscription?.symbols} symbols`}
                                            name="qualification"
                                        >
                                            <TextArea disabled={!companySubscription?.qualification} showCount maxLength={companySubscription?.symbols} rows={3} />
                                        </Form.Item>

                                        <div className="edit-block">
                                            <h2>What we offer</h2>
                                        </div>
                                        <Form.Item
                                            className="max-symbol-label"
                                            label={`Max ${companySubscription?.symbols} symbols`}
                                            name="weOffer"
                                        >
                                            <TextArea disabled={!companySubscription?.we_offer} showCount maxLength={companySubscription?.symbols} rows={3} />
                                        </Form.Item>

                                        <div className="edit-block">
                                            <h2>Job Offer Category</h2>
                                        </div>

                                        <div className="add-job-offer-category">
                                            <Form.Item
                                                name="categories"
                                            >
                                                <Select
                                                    popupClassName="custom-multiple-type-select"
                                                    // mode='multiple'
                                                    maxTagCount='responsive'
                                                    placeholder='Select category'
                                                    options={categories}
                                                    optionRender={(option) => (
                                                        <Flex vertical>
                                                            <span role="img" aria-label={option.data.label}>
                                                                {option?.data.label}
                                                            </span>
                                                            <small style={{textWrap: 'balance'}}>{option?.data.description}</small>
                                                        </Flex>
                                                    )}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="edit-block">
                                            <h2>Office pictures</h2>
                                        </div>

                                        <div className="picture-wall-upload text-left">
                                            <p>Upload office pictures</p>
                                            <Upload
                                                action={API_URL+"/job/upload/photos"}
                                                headers={apiService.authHeader()}
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={handlePreview}
                                                onChange={handleChange}
                                                onRemove={(event) => onRemoveImageFunc(event)}
                                                multiple={true}
                                                name='photos'
                                                className="tetetetetet"
                                                maxCount={companySubscription?.photos}
                                            >
                                                {fileList.length >= companySubscription?.photos ? null : uploadButton}
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ConfigProvider>
                </div>
            </div>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} className="office-picture-job-offer">
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    )
}

export default EditJobOffer;
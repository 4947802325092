import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from "react-router-dom";
import apiService from "../../services/api.service";
import EditJobOffer from './EditJobOffer';
import {errorCodeList} from '../../constants/errorCode';
import {DRIVING_LICENCE, IMG_URL} from "../../constants/index";
import './job-offers.css';
import {useSelector, useDispatch} from 'react-redux';
import {setIsJobOfferEdit} from '../../slices/job';
import {ReactComponent as PublishJobIcon} from "../../assets/img/icon_new_outline.svg";
import {Button, Row, Col, Input, ConfigProvider, Image, Flex} from 'antd';


const {TextArea} = Input;

const ViewJobOffer = () => {
    let {id} = useParams();
    let {state} = useLocation();
    const [jobOffer, setJoboffer] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [editJobOffer, setEditJobOffer] = useState(false);
    const [jobOfferTimeType, setJobOfferTimeType] = useState([]);
    const [location, setLocation] = useState(null);
    const workTimeType = useSelector(state => state.launch.workTimeType);
    const categories = useSelector(state => state.launch.jobOfferCategory);
    const isJobOfferEdit = useSelector(state => state.jobOffer.isJobOfferEdit);
    const dispatch = useDispatch();

    const getJobDetailsFunc = async () => {
        const jobIdObj = {
            jobId: Number(id)
        }

        try {
            await apiService.getJobDetails(jobIdObj).then(response => {
                setJoboffer(response.data)
            }).catch(err => console.log('getJobDetails err', err))
        } catch (error) {
            console.log("getJobDetailsFunc error", error)
        }
    }

    const currencyFormat = (price) => price.toLocaleString("en-US", {style: "currency", currency: "EUR", minimumFractionDigits: 0, });

    const getWorkTypeTimeFunc = async (data) => {
        for (let i = 0; i < data?.length; i++) {
            data[i].label = workTimeType.find(item => item.id === data[i].worktimetypeId).text
        }
        setJobOfferTimeType(data)
    }

    const getLocationFunc = async (data) => {
        let resp = await apiService.getLocation();

        const locationList = resp.data['list'].map(item => {
            return {
                ...item,
                label: item.name,
                value: item.id,
            }
        })

        if (data) {
            locationList.find(loc => {
                if (loc.id === data?.countryId) {
                    data.countryName = loc.name
                    data.label = loc.name
                    data.value = loc.countryId
                    data.city = loc.City.find(city => city.id === data.id)
                }
            })

            data.city = {
                ...data.city,
                label: data.city.name,
                value: data.city.id
            }
        }

        setLocation(locationList);
    }

    useEffect(() => {
        getJobDetailsFunc()
    }, [])

    useEffect(() => {
        if (!jobOffer) return;

        setFileList(jobOffer?.Photos)
        getWorkTypeTimeFunc(jobOffer?.WorkTimeType)
        getLocationFunc(jobOffer?.Location)

        if (categories) {
            const selectedCategories = categories.find(item => item.id === jobOffer?.JobOfferCategory?.id)

            if (selectedCategories) {
                const {description, id, title, ...other} = selectedCategories
                jobOffer.JobOfferCategory = {
                    description: description,
                    id: id,
                    title: title,
                    ...other
                }
            }
        }

        const driveLicense = jobOffer?.DrivingLicense?.map(item => {
            DRIVING_LICENCE.filter(el => {
                if (el.label === item.category) {
                    item = {
                        ...item,
                        value: el.value
                    }
                }
            })
            return item
        })

        if (driveLicense) {
            jobOffer.DrivingLicense = driveLicense
        }

        console.log("jobOffer", jobOffer)
        // dispatch(setIsJobOfferEdit(true))
    }, [jobOffer]);

    return (
        <div className="container job-offer-details">
            <div className="custom-padding">
                <div className="job-information text-left">
                    <h2>Job Offer</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales <br /> sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                </div>
                {!isJobOfferEdit && jobOffer ? (
                    <div className="subscription-special-block position-relative">
                        <div className="subscription-true-block">
                            <Row className="job-offers-search-block">
                                <Col xs={{span: 16, offset: 8}} md={{span: 8, offset: 16}} xl={{span: 6, offset: 18}} >
                                    <div className="publish-job-section">
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Button: {
                                                        primaryShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                                                        controlHeight: 40,
                                                        colorPrimary: '#732135',
                                                        // colorPrimaryHover: '#ffffff',
                                                        // colorPrimaryActive: '#8f2942',
                                                    },
                                                },
                                            }}
                                        >
                                            <Button type="primary" className="publish-job-btn" onClick={() => dispatch(setIsJobOfferEdit(true))} block icon={<PublishJobIcon />}>Edit Job Info</Button>
                                        </ConfigProvider>
                                    </div>
                                </Col>
                            </Row>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        InputNumber: {
                                            activeBorderColor: '#732135',
                                            hoverBorderColor: '#732135',
                                            controlOutline: 'rgba(115, 34, 52, 0.1);',
                                            optionSelectedBg: '#732135',
                                        },
                                        Input: {
                                            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                                            activeBorderColor: '#732135',
                                            hoverBorderColor: '#732135',
                                            colorBorder: '#aeaeae',
                                        },
                                        Select: {
                                            colorBorder: '#aeaeae',
                                            controlOutline: 'rgba(115, 34, 52, 0.1);',
                                            optionSelectedBg: '#732135',
                                            colorPrimary: '#732135',
                                            colorPrimaryHover: '#732135',
                                        }
                                    },
                                }}
                            >

                                <Row className='company-details-step add-job-block' gutter={12}>
                                    <Col xs={24} lg={12}>
                                        <div className="edit-block">
                                            <h2>Job Position</h2>
                                        </div>
                                        <div className="job-details-content text-left">
                                            <pre>
                                                {jobOffer.title}
                                            </pre>
                                        </div>

                                        <div className="edit-block">
                                            <h2>Job Details</h2>
                                        </div>

                                        <Row className='job-details-row'>
                                            <Col span={8}>
                                                <p className="custom-label">Expected Salary:</p>
                                                <p><small>Min:</small> <span className='currency-format'>{currencyFormat(+jobOffer.salary_min)}</span></p>
                                            </Col>
                                            <Col span={8}>
                                                <p className="custom-label"></p>
                                                <p><small>Max:</small> <span className='currency-format'>{currencyFormat(+jobOffer.salary_max)}</span></p>
                                            </Col>
                                            <Col span={8}>
                                                <p className="custom-label">Job type:</p>
                                                {jobOfferTimeType && jobOfferTimeType.map(item => (
                                                    <p key={item.id}>{item.label}</p>
                                                ))}
                                            </Col>
                                        </Row>

                                        <Row className='job-details-row'>
                                            <Col span={8}>
                                                <p className="custom-label">Country:</p>
                                                <p>{jobOffer.Location?.countryName}</p>
                                            </Col>
                                            <Col span={8}>
                                                <p className="custom-label">City:</p>
                                                <p>{jobOffer.Location?.name}</p>
                                            </Col>
                                            <Col span={8}>
                                                <p className="custom-label">Zip code:</p>
                                                <p>{jobOffer.zip}</p>
                                            </Col>
                                        </Row>

                                        <Row className='job-details-row'>
                                            <Col>
                                                <p className="custom-label">Street:</p>
                                                <p>{jobOffer.street}</p>
                                            </Col>
                                        </Row>

                                        <Row className='job-details-row'>
                                            <Col span={8}>
                                                <p className="custom-label">Languages:</p>
                                                {jobOffer?.SpeakLanguages?.map(item => (
                                                    <p key={item.id}>{item.language}</p>
                                                ))}
                                            </Col>
                                            <Col span={8}>
                                                <p className="custom-label">Level:</p>
                                                {jobOffer?.SpeakLanguages?.map(item => (
                                                    <p key={item.id}>{item.level}</p>
                                                ))}
                                            </Col>
                                            <Col span={8}></Col>
                                        </Row>

                                        <Row className='job-details-row'>
                                            <Col span={8}>
                                                <p className="custom-label">Driving license:</p>
                                                {jobOffer?.DrivingLicense?.map(item => (
                                                    <p key={item.id}>{item.category}</p>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24} lg={{span: 11, offset: 1}}>
                                        <div className="form-section">
                                            <div className="edit-block">
                                                <h2>Your Tasks</h2>
                                            </div>
                                            <div className="job-details-content text-left">
                                                <pre>
                                                    {jobOffer.tasks}
                                                </pre>
                                            </div>

                                            <div className="edit-block">
                                                <h2>Our requirements (Qualification)</h2>
                                            </div>

                                            <div className="job-details-content text-left">
                                                <pre>
                                                    {jobOffer.qualification}
                                                </pre>
                                            </div>

                                            <div className="edit-block">
                                                <h2>What we offer</h2>
                                            </div>

                                            <div className="job-details-content text-left">
                                                <pre>
                                                    {jobOffer.we_offer}
                                                </pre>
                                            </div>

                                            <div className="edit-block">
                                                <h2>Job Offer Category</h2>
                                            </div>

                                            <div className="job-details-content text-left">
                                                <pre>
                                                    {jobOffer?.JobOfferCategory?.title}
                                                </pre>
                                                <p style={{marginTop: '0', textWrap: 'balance'}}>
                                                    {jobOffer?.JobOfferCategory?.description}
                                                </p>
                                            </div>

                                            <div className="edit-block">
                                                <h2>Office pictures</h2>
                                            </div>

                                            <div className="picture-wall-upload view-office-pictures text-left">
                                                <p>Upload office pictures</p>
                                                {/* {IMG_URL + '/' + fileList[0]?.photo?.path} */}
                                                <div className="image-list">
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                                        }}
                                                    >
                                                        {fileList && fileList.map(item => (
                                                            <Image key={item.id} crossOrigin="anonymous" width={84} src={IMG_URL + '/' + item?.photo?.path} />
                                                        ))}
                                                    </Image.PreviewGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </ConfigProvider>
                        </div>
                    </div>
                ) : (
                    <div className="subscription-special-block position-relative">
                        <div className="subscription-true-block">
                            <Row className='company-details-step add-job-block' gutter={12}>
                                {jobOffer && location && <EditJobOffer jobOffer={jobOffer} locations={location} />}
                            </Row>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ViewJobOffer
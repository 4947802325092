import React, {useEffect, useState} from 'react';
import './candidates.css';
import '../upgrade/upgrade.css';
import {useNavigate, useOutletContext, useLocation, useParams} from "react-router-dom";
import {DRIVING_LICENCE, IMG_URL} from "../../constants/index";
import UserNoImg from '../../assets/img/user_no_image.svg';
import UpgradeBanner from '../upgrade/UpgradeBanner';
import {useSelector} from 'react-redux';
import apiService from "../../services/api.service";

import {Layout, Flex, Button, Card, Row, Col, Image, ConfigProvider} from 'antd';
import {partialRight} from 'lodash';
import {LeftOutlined, ArrowRightOutlined} from '@ant-design/icons';

const {Header, Footer, Sider, Content} = Layout;

const ViewCandidatesInfo = () => {
    let {state} = useLocation();
    const navigate = useNavigate();
    let {id} = useParams();
    // const { go, goBack, goForward } = useHistory();
    const [stateDataUser, setStateDataUser] = useState(null);
    const [dataUser, setDataUser] = useState(null);
    const [userContacts, setUserContacts] = useState(null);
    const [companySubscription, setCompanySubscription] = useState(null);
    const company = useOutletContext();
    const categories = useSelector(item => item.launch.jobOfferCategory);

    const currencyFormat = (price) => price.toLocaleString("en-US", {style: "currency", currency: "EUR", minimumFractionDigits: 0, });

    const getUserImage = (src) => {
        if (src) {
            return IMG_URL + '/' + src
        } else {
            return UserNoImg
        }
    }

    const getUserCategories = (item) => {
        return categories.find(cat => cat.id === item.joboffercategoryId)?.title
    }

    const getUserContacts = () => {
        setUserContacts({
            email: dataUser['email'],
            phone: dataUser['phone'],
            languages: dataUser['CVState'].additional_details.languages,
            driving_license: dataUser['CVState'].additional_details.driving_license.map(item => item.category)
        })
    }

    const dateExperienceFunc = (date) => {
        const options = {month: 'short', year: 'numeric'};
        const today = new Date(date);
        return today.toLocaleDateString("en-GB", options)
    }

    const getUserInfoLikeFunc = async (likeId) => {
        try {
            let resp = await apiService.getUserInfoLike({likeId: likeId})
            setStateDataUser(resp.data)
        } catch (error) {
            console.log("getUserInfoLikeFunc error", error)
        }
    }

    const buyFreeCandidatesContact = async () => {
        const payObj = {
            jobId: stateDataUser.jobId,
            likeId: stateDataUser.id
        }

        console.log('payObj', payObj)

        try {
            await apiService.getFreeCandidates(payObj).then(response => {
                console.log('buyFreeCandidatesContact response', response)
                // window.location.reload()
                getUserInfoLikeFunc(stateDataUser.id)
            })
        } catch (error) {
            console.log('buyFreeCandidatesContact error', error)
        }
    }

    const buyCandidatesContact = (priceMatch) => {
        let price = Number(priceMatch)

        if (price <= 0) {
            buyFreeCandidatesContact();
        } else {
            navigate("/payment/" + id, {state: {likeId: stateDataUser.id}});
        }
    }

    useEffect(() => {
        // if (!Object.keys(state).length) return
        console.log('id id id', id)
        if (!state?.likeId) {
            getUserInfoLikeFunc(id)
        } else {
            getUserInfoLikeFunc(state.likeId)
        }
    }, [state])

    useEffect(() => {
        if (!stateDataUser) return;
        console.log("stateDataUser", stateDataUser)
        setDataUser(stateDataUser?.User)
    }, [stateDataUser])

    useEffect(() => {
        console.log('categories', categories)
    }, [])

    useEffect(() => {
        if (!dataUser) return;
        getUserContacts()
    }, [dataUser])

    useEffect(() => {
        if (!company) return;
        const activeSubscription = company[0].CompanySubscription?.find(item => item.active)
        setCompanySubscription(activeSubscription?.subscription)
        // console.log("company 444", company)
    }, [company])

    useEffect(() => {
        if (!companySubscription) return;
        console.log('companySubscription', companySubscription)
    }, [companySubscription])

    // useEffect(() => {
    //     if (!userContacts) return;
    //     console.log('userContacts', userContacts)
    // }, [userContacts])

    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        itemBorderRadius: '0',
                        itemSelectedBg: 'transparent',
                        itemActiveBg: 'transparent',
                        itemHoverBg: '#f2e7ea',
                        itemMarginInline: 0,
                        itemHoverColor: '#732135',
                    },
                    Button: {
                        defaultBorderColor: '#732135',
                        defaultHoverBorderColor: '#732135',
                        defaultActiveBorderColor: '#732135',
                        lineWidth: 2,
                    },
                },
            }}
        >
            {dataUser && (
                <div className="container">
                    <div className="custom-padding">
                        <div className='text-left'>
                            <Button type="text" onClick={() => navigate('/candidates-list/' + stateDataUser.jobId)} icon={<LeftOutlined />}>
                                Back
                            </Button>
                        </div>

                        <Card className="mobile-company-side-block">
                            <Row justify="space-between" align="middle">
                                <Col>
                                    <Flex justify='flex-start' align='center'>
                                        <Image
                                            width={50}
                                            src={getUserImage(dataUser.photo?.path)}
                                            preview={false}
                                        />
                                        <span className='candidates-name'>
                                            {dataUser.firstName} {dataUser.lastName}
                                        </span>
                                    </Flex>
                                </Col>
                                <Col>
                                    <div key={id.toString()} className='mobile-categories-list'>
                                        {dataUser.profile.categories.map((item) => (
                                            <p key={item.id}>{getUserCategories(item)}</p>
                                        ))}
                                    </div>
                                </Col>
                                <Col>
                                    {!stateDataUser.visible && (
                                        <Button type="primary" onClick={() => buyCandidatesContact(companySubscription?.price_match)} className='buy-candidates-contact-btn' block>
                                            <span>Candidate contacts - <span className='price-match'>{currencyFormat(+companySubscription?.price_match)}</span></span>
                                        </Button>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <div className="mobile-upgrade-account-banner">
                                        <div className="banner-content">
                                            <div>
                                                <h3>Upgrade account</h3>
                                                <p>Subscribe to a plan and enjoy advanced features</p>
                                            </div>
                                            <Button shape="round" size='large' onClick={() => navigate("/subscription")}>
                                                Try now <ArrowRightOutlined />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>

                        <Layout className='company-details-section company-details-step-three view-candidates-info'>
                            <Sider width="25%" theme='light' className='company-sider-block'>
                                <div className='company-name-section w-100'>
                                    <div className="company-info-block user text-center">
                                        <Image
                                            width={80}
                                            src={getUserImage(dataUser.photo?.path)}
                                            preview={false}
                                        />
                                        <h2 className='company-name text-center'>{dataUser.firstName} {dataUser.lastName}</h2>
                                        {dataUser.profile.categories.map(item => (
                                            <h4 key={item.id} className='candidates-categories text-center'>{getUserCategories(item)}</h4>
                                        ))}
                                        {!stateDataUser.visible && (
                                            <Button type="primary" onClick={() => buyCandidatesContact(companySubscription?.price_match)} className='buy-candidates-contact-btn' block>
                                                <span>Candidate contacts - <span className='price-match'>{currencyFormat(+companySubscription?.price_match)}</span></span>
                                            </Button>
                                        )}
                                    </div>

                                    <UpgradeBanner />
                                </div>
                            </Sider>
                            <Layout>
                                <Content>
                                    <div className="company-information-block">
                                        <div className="information contact">
                                            <div className="edit-block">
                                                <h2>Candidate contacts</h2>
                                            </div>

                                            {userContacts && Object.entries(userContacts).map((item, idx) => (
                                                <div key={idx} className='d-flex edit-info-company'>
                                                    <>
                                                        <div className="contact-data-label">{item[0] === 'driving_license' ? 'Driving license' : item[0]}:</div>
                                                        {item[0] !== 'languages' && item[0] !== 'driving_license' && <div className="contact-data-value">{item[1]}</div>}
                                                        {item[0] === 'languages' && item[1].map((elem, index) => (
                                                            <div key={elem.id}>
                                                                <div className="contact-data-value">
                                                                    {elem.language} ({elem.level})
                                                                    {index !== item[1].length - 1 && (<>,</>)}
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {item[0] === 'driving_license' && item[1].map((elem, index) => (
                                                            <div key={index}>
                                                                <div className="contact-data-value">
                                                                    {elem}
                                                                    {index !== item[1].length - 1 && (<>,</>)}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="about">
                                            <div className="edit-block">
                                                <h2>Job experience</h2>
                                            </div>
                                            <ul>
                                                {dataUser.CVState.job_experience.map(item => (
                                                    <li key={item.id} className='job-experience'>
                                                        <h4>{dateExperienceFunc(item.start_date)} - {!item.currently_work_here ? 'Present' : dateExperienceFunc(item.end_date)}</h4>
                                                        <pre>{item.job_title} | {item.employer} | {item.country} | {item.city}</pre>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="contact">
                                            <div className="edit-block">
                                                <h2>Education</h2>
                                            </div>
                                            <ul>
                                                {dataUser.CVState.education.map(item => (
                                                    <li key={item.id} className='job-experience'>
                                                        <h4>{dateExperienceFunc(item.end_date)}</h4>
                                                        <pre>{item.field_of_study} | {item.school_name} | {item.school_location} </pre>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="activities skills">
                                            <div className="edit-block">
                                                <h2>Skills</h2>
                                            </div>
                                            <ul>
                                                {dataUser.CVState.skills && (
                                                    <li className='job-experience'>
                                                        <h4><pre>{dataUser.CVState.skills}</pre></h4>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="activities">
                                            <div className="edit-block">
                                                <h2>About me</h2>
                                            </div>
                                            <ul>
                                                {dataUser.CVState.about_me && (
                                                    <li className='job-experience'>
                                                        <h4><pre>{dataUser.CVState.about_me}</pre></h4>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </Content>
                            </Layout>
                        </Layout>
                    </div>
                </div>
            )}

        </ConfigProvider>
    )
}

export default ViewCandidatesInfo
import React from 'react';
import './upgrade.css';
import UpgradeBannerImg from '../../assets/img/upgrade-banner.png';
import {useNavigate} from "react-router-dom";

import {Button, Image} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';

const UpgradeBanner = () => {
    const navigate = useNavigate();
    return (
        <div className="upgrade-account-banner w-100">
            {/* <img src={UpgradeBanner} alt="Upgrade" /> */}
            <Image src={UpgradeBannerImg} preview={false} className='w-100' />
            <div className="banner-content">
                <h3>Upgrade account</h3>
                <p>Subscribe to a plan and enjoy advanced <br /> features</p>
                <Button shape="round" size='large' onClick={() => navigate("/subscription")}>
                    Try now <ArrowRightOutlined />
                </Button>
            </div>
        </div>
    )
}

export default UpgradeBanner
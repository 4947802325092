import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import './add-job.css';
import {errorCodeList} from '../../constants/errorCode';
import apiService from "../../services/api.service";
import {DRIVING_LICENCE, API_URL} from '../../constants/index';
import {useSelector} from 'react-redux';
import {Space, Table, Tag, Form, Upload, Modal, Select, Button, InputNumber, Row, Col, Popover, Input, ConfigProvider, Image, Flex} from 'antd';
import {PlusOutlined, MinusOutlined, ArrowRightOutlined, ExclamationCircleFilled} from '@ant-design/icons';
import {ReactComponent as PublishJobIcon} from "../../assets/img/icon_new_outline.svg";
import UpgradeBanner from '../upgrade/UpgradeBanner';
const {TextArea} = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const AddJob = () => {
    const navigate = useNavigate();
    const [cityOptions, setCityOptions] = useState([]);
    const [location, setLocation] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [form] = Form.useForm();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [company, messageApi] = useOutletContext();
    const [companySubscription, setCompanySubscription] = useState([]);
    const [workTimeType, setWorkTimeType] = useState([]);
    const [fileList, setFileList] = useState([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
    ]);
    const [languageLevel, setLanguageLevel] = useState(['A1', 'A2', 'B1', 'B2', 'C1', 'C2']);
    const workType = useSelector(state => state.launch.workTimeType);
    const categories = useSelector(state => state.launch.jobOfferCategory);

    const onPublishJob = async () => {
        console.log("onPublishJob form", form.getFieldValue())
        const {
            jobPosition,
            minSalary,
            maxSalary,
            worktimetype,
            country,
            city,
            zipCode,
            street,
            speaklanguage,
            drivinglicense,
            yourTasks,
            qualification,
            categories,
            weOffer
        } = form.getFieldValue();

        console.log("speaklanguage", Object.keys(speaklanguage[0]).length, speaklanguage)

        let publishJobForm = {}

        if (company) {
            publishJobForm = {
                companyId: company.id,
                locationId: Number(city) || null,
                title: jobPosition,
                zip: zipCode,
                street: street,
                tasks: yourTasks,
                qualification: qualification,
                worktimetype: worktimetype,
                we_offer: weOffer,
                speaklanguage: Object.keys(speaklanguage[0]).length > 0 ? speaklanguage : null,
                joboffercategoryId: categories,
                drivinglicense: DRIVING_LICENCE.filter((item, index) => drivinglicense?.find(el => el === item.value)).map(elem => elem.label) || [],
                photos: fileList.map(item => {return {id: item.response[0].id}}),
                salary_min: Number(minSalary) || null,
                salary_max: Number(maxSalary) || null,
                description: 'description company.id => ' + company.id
            }
        }
        console.log("publishJobForm", publishJobForm)

        try {
            await apiService.addNewJob(publishJobForm).then(response => {
                console.log("response addNewJob", response)
                messageApi.open({
                    className: 'open-message-style',
                    duration: 5,
                    type: 'success',
                    content: 'You have successfully added a new job',
                });
                navigate("/job-offers");
                form.resetFields()
            })
        } catch (error) {
            console.log('onPublishJob error', error)
            messageApi.open({
                className: 'open-message-style',
                duration: 5,
                type: 'error',
                content: error.response.data.message,
            });
        }
    }

    const getWorkTimeTypeFunc = () => {

        const workTypeObj = workType.map(item => {
            return {
                keyName: item.name_key,
                label: item.text,
                value: item.name_key,
                id: item.id.toString()
            }
        })

        console.log("workTypeObj", workTypeObj)
        console.log("workType", workType)

        setWorkTimeType(workTypeObj);
    }

    const handleFormChange = (changedValues, allValues) => {
        const formValues = allValues.map(item => item.name)
        // console.log("formValues", formValues)
    }

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
        },
        labelAlign: 'left'
    };

    const handleCountryChange = (event) => {
        let selectedCity = location.find(loc => loc.name.toLowerCase() === event).City
        setCityOptions(selectedCity)
    }

    const getLocationFunc = async () => {
        let resp = await apiService.getLocation();
        setLocation(resp.data['list']);
    }

    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleCancel = () => setPreviewOpen(false);

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    useEffect(() => {
        getWorkTimeTypeFunc();
    }, [])

    useEffect(() => {
        console.log("company www", company);
        if (company) {
            const activeSubscription = company?.CompanySubscription?.find(item => item.active)
            setCompanySubscription(activeSubscription?.subscription)
            getLocationFunc();
        }
    }, [company])

    useEffect(() => {
        console.log("categories", categories)
    }, [categories])

    useEffect(() => {
        console.log("companySubscription", companySubscription)
    }, [companySubscription])

    return (
        <div className="container">
            <div className="custom-padding">
                <div className="job-information text-left">
                    <h2>Add a Job</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales <br /> sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                </div>
                <div className="subscription-special-block position-relative">
                    <div className="subscription-true-block">
                        <Row className="job-offers-search-block">
                            <Col xs={{span: 12, offset: 12}} md={{span: 8, offset: 16}} xl={{span: 6, offset: 18}}>
                                <div className="publish-job-section">
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Button: {
                                                    defaultBg: '#51ad65',
                                                    defaultBorderColor: '#51ad65',
                                                    defaultActiveBorderColor: '#74d789',
                                                    defaultActiveBg: '#74d789',
                                                    defaultHoverColor: '#68be7a',
                                                    controlHeight: 40,
                                                    defaultHoverBg: '#68be7a',
                                                    defaultHoverBorderColor: '#68be7a',
                                                    defaultShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                                                    colorBgContainerDisabled: '#8ed49d'
                                                },
                                            },
                                        }}
                                    >
                                        <Button className="publish-job-btn add-job-comp" disabled={company?.Offers >= companySubscription?.offers} onClick={onPublishJob} block icon={<PublishJobIcon />}>Publish Job</Button>
                                    </ConfigProvider>
                                </div>
                            </Col>
                        </Row>
                        <ConfigProvider
                            theme={{
                                components: {
                                    InputNumber: {
                                        activeBorderColor: '#732135',
                                        hoverBorderColor: '#732135',
                                        controlOutline: 'rgba(115, 34, 52, 0.1);',
                                        optionSelectedBg: '#732135',
                                    },
                                    Input: {
                                        activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                                        activeBorderColor: '#732135',
                                        hoverBorderColor: '#732135',
                                        colorBorder: '#aeaeae',
                                    },
                                    Select: {
                                        colorBorder: '#aeaeae',
                                        controlOutline: 'rgba(115, 34, 52, 0.1);',
                                        optionSelectedBg: '#732135',
                                        colorPrimary: '#732135',
                                        colorPrimaryHover: '#732135',
                                    }
                                },
                            }}
                        >
                            <Form
                                onFieldsChange={handleFormChange}
                                name='companyDetailsForm'
                                form={form} {...formItemLayout}
                                initialValues={{
                                    speaklanguage: [{}],
                                }}
                            >
                                <Row className='company-details-step add-job-block' gutter={12}>
                                    <Col xs={24} lg={12}>
                                        <div className="edit-block">
                                            <h2>Job Position</h2>
                                        </div>
                                        <Form.Item
                                            className="max-symbol-label"
                                            label={`Max ${companySubscription?.symbols} symbols`}
                                            name="jobPosition"
                                        // rules={[
                                        //     {
                                        //         // required: true,
                                        //         message: 'Please input!',
                                        //     },
                                        // ]}
                                        >
                                            <TextArea showCount maxLength={companySubscription?.symbols} rows={3} />
                                        </Form.Item>

                                        <div className="edit-block">
                                            <h2>Job Details</h2>
                                        </div>

                                        <div className='city-zip-style'>
                                            <Form.Item
                                                label="Expected Salary:"
                                                name="minSalary"
                                                style={{
                                                    width: 'calc(50%)',
                                                }}
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <Input placeholder='Min' />
                                            </Form.Item>

                                            <Form.Item
                                                label=' '
                                                name="maxSalary"
                                                style={{
                                                    width: 'calc(50%)',
                                                }}
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <Input placeholder='Max' />
                                            </Form.Item>

                                            <Form.Item
                                                label="Job type:"
                                                name="worktimetype"
                                                style={{
                                                    width: '180px',
                                                }}
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <Select
                                                    popupClassName="custom-multiple-type-select"
                                                    placeholder='Select Item...'
                                                    mode='multiple'
                                                    maxTagCount='responsive'
                                                    // defaultValue={workTimeType[0]}
                                                    options={workTimeType.map((item) => ({
                                                        label: item.label,
                                                        value: item.id,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className='city-zip-style'>
                                            <Form.Item
                                                label="Country:"
                                                name="country"
                                                style={{
                                                    width: 'calc(50%)',
                                                }}
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <Select
                                                    onChange={handleCountryChange}
                                                    options={location.map((loc) => ({
                                                        label: loc.name,
                                                        value: loc.name.toLowerCase(),
                                                    }))} />
                                            </Form.Item>

                                            <Form.Item
                                                label="City:"
                                                name="city"
                                                style={{
                                                    width: 'calc(50%)',
                                                }}
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label.toLowerCase() ?? '').toLowerCase().localeCompare((optionB?.label.toLowerCase() ?? '').toLowerCase())
                                                    }
                                                    options={cityOptions.map((item) => ({
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                    }))} />
                                            </Form.Item>

                                            <Form.Item
                                                label="Zip Code:"
                                                name="zipCode"
                                                style={{
                                                    width: '180px',
                                                }}
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                            label="Street:"
                                            name="street"
                                        // rules={[
                                        //     {
                                        //         // required: true,
                                        //         message: 'Please input!',
                                        //     },
                                        // ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.List name="speaklanguage">
                                            {(fields, {add, remove}) => (
                                                <>
                                                    {fields.map((field, idx) => (
                                                        <div className='city-zip-style' key={field.key}>
                                                            <Form.Item
                                                                label={idx === 0 && "Spoken languages:"}
                                                                name={[field.name, 'language']}
                                                                style={{
                                                                    width: 'calc(50%)',
                                                                }}
                                                            // rules={[
                                                            //     {
                                                            //         // required: true,
                                                            //         message: 'Missing language',
                                                            //     },
                                                            // ]}
                                                            >
                                                                <Input placeholder="English" />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={idx === 0 && "Level:"}
                                                                name={[field.name, 'level']}
                                                                style={{
                                                                    width: 'calc(50%)',
                                                                }}
                                                            // rules={[
                                                            //     {
                                                            //         // required: true,
                                                            //         message: 'Missing level',
                                                            //     },
                                                            // ]}
                                                            >
                                                                <Select
                                                                    placeholder="A1"
                                                                    options={languageLevel.map((item, index) => ({
                                                                        label: item,
                                                                        value: item,
                                                                    }))}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                className="lang-btn-style"
                                                                label={idx === 0 && " "}
                                                                name={[field.name, 'button']}
                                                                style={{
                                                                    width: '100px',
                                                                }}
                                                            >
                                                                {idx === 0 && (<Button shape="circle" onClick={() => add()} icon={<PlusOutlined />} />)}
                                                                {idx > 0 && (<Button shape="circle" onClick={() => remove(field.name)} icon={<MinusOutlined />} />)}
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>

                                        <Form.Item
                                            label="Driving license:"
                                            name="drivinglicense"
                                            style={{
                                                width: '205px',
                                            }}
                                        // rules={[
                                        //     {
                                        //         // required: true,
                                        //         message: 'Please input!',
                                        //     },
                                        // ]}
                                        >
                                            <Select
                                                popupClassName="custom-multiple-type-select"
                                                mode='multiple'
                                                maxTagCount='responsive'
                                                placeholder='B'
                                                options={DRIVING_LICENCE}
                                            />
                                        </Form.Item>

                                    </Col>

                                    <Col xs={24} lg={{span: 11, offset: 1}} >
                                        <div className="form-section">
                                            <div className="edit-block">
                                                <h2>Your Tasks</h2>
                                            </div>
                                            <Form.Item
                                                className="max-symbol-label"
                                                label={`Max ${companySubscription?.symbols} symbols`}
                                                name="yourTasks"
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <TextArea disabled={!companySubscription?.tasks} showCount maxLength={companySubscription?.symbols} rows={3} />
                                            </Form.Item>

                                            <div className="edit-block">
                                                <h2>Our requirements (Qualification)</h2>
                                            </div>
                                            <Form.Item
                                                className="max-symbol-label"
                                                label={`Max ${companySubscription?.symbols} symbols`}
                                                name="qualification"
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <TextArea disabled={!companySubscription?.qualification} showCount maxLength={companySubscription?.symbols} rows={3} />
                                            </Form.Item>

                                            <div className="edit-block">
                                                <h2>What we offer</h2>
                                            </div>
                                            <Form.Item
                                                className="max-symbol-label"
                                                label={`Max ${companySubscription?.symbols} symbols`}
                                                name="weOffer"
                                            // rules={[
                                            //     {
                                            //         // required: true,
                                            //         message: 'Please input!',
                                            //     },
                                            // ]}
                                            >
                                                <TextArea disabled={!companySubscription?.we_offer} showCount maxLength={companySubscription?.symbols} rows={3} />
                                            </Form.Item>

                                            <div className="edit-block">
                                                <h2>Job Offer Category</h2>
                                            </div>

                                            <div className="add-job-offer-category">
                                                <Form.Item
                                                    name="categories"
                                                >
                                                    <Select
                                                        popupClassName="custom-multiple-type-select"
                                                        // mode='multiple'
                                                        maxTagCount='responsive'
                                                        placeholder='Select category'
                                                        options={categories}
                                                        optionRender={(option) => (
                                                            <Flex vertical>
                                                                <span role="img" aria-label={option.data.label}>
                                                                    {option?.data.label}
                                                                </span>
                                                                <small style={{textWrap: 'balance'}}>{option?.data.description}</small>
                                                            </Flex>
                                                        )}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="edit-block">
                                                <h2>Office pictures</h2>
                                            </div>


                                            <div className="picture-wall-upload text-left">
                                                <p>Upload office pictures</p>
                                                <Upload
                                                    action={API_URL + "/job/upload/photos"}
                                                    headers={apiService.authHeader()}
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    onPreview={handlePreview}
                                                    onChange={handleChange}
                                                    multiple={true}
                                                    name='photos'
                                                    maxCount={companySubscription?.photos}
                                                >
                                                    {fileList.length >= companySubscription?.photos ? null : uploadButton}
                                                </Upload>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ConfigProvider>
                    </div>
                    {company?.Offers >= companySubscription?.offers && (
                        <div className="subscription-false-block">
                            <Flex justify='center' align='center' vertical>
                                <Flex align='center' justify='space-between' className="limit-subscribtion-message">
                                    <div className="limit-text">
                                        <h3>You have reached the limit for adding jobs!</h3>
                                        <small>To add more jobs, please upgrade your subscription plan</small>
                                    </div>
                                    <ExclamationCircleFilled />
                                </Flex>
                                <Flex justify='center' align='center' vertical className='company-name-section'>
                                    <UpgradeBanner />
                                </Flex>
                            </Flex>
                        </div>
                    )}
                </div>

                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </div>
        </div>
    )
}

export default AddJob;
import React, {useEffect, useState} from 'react';
import './payment.css';
import Loader from '../loader/Loader';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import apiService from "../../services/api.service";

import {Button, Result, Divider, ConfigProvider} from 'antd';

const PaymentSuccess = () => {
  let {id, transactionId} = useParams();

  let navigate = useNavigate();

  const [paymentData, setPaymentData] = useState(null)

  const currencyFormat = (price) => price.toLocaleString("en-US", {style: "currency", currency: "EUR", minimumFractionDigits: 0, });

  const getPaymentHistoryFunc = async (transactionId) => {
    console.log('transactionId', transactionId)
    const params = {
      pagination: {
        take: 10,
        skip: 0
      }
    }

    try {
      let resp = await apiService.getPaymentHistory(params)
      const respData = resp.data.find(item => item.transactionId === transactionId)
      console.log('respData', respData)
      setPaymentData(respData)
    } catch (error) {
      console.log("getUserInfoLikeFunc error", error)
    }
  }

  const returnToCandidates = () => {
    navigate('/view-candidates-info/' + paymentData.targetId)
  }

  const returnToCandidatesList = () => {
    navigate('/candidates-list/' + id)
  }

  const paymentDetails = (paymentData) => {
    return (
      <>
        {paymentData && (
          <>
            <span className='details-label'>Date: <span>{new Date(paymentData.createdAt).toLocaleDateString()}</span></span> <Divider type="vertical" />
            <span className='details-label'>Tranzaction number: <span>{paymentData.transactionId}</span></span> <Divider type="vertical" />
            <span className='details-label'>Method: <span>{paymentData.method.toUpperCase()}</span></span> <Divider type="vertical" />
            <span className='details-label'>Total: <span className='payment-sum'>{currencyFormat(Number(paymentData.sum))}</span></span>
          </>
        )}
      </>
    )
  }

  useEffect(() => {
    getPaymentHistoryFunc(transactionId)
    console.log('id', id)
  }, [transactionId])

  useEffect(() => {
    if (!paymentData) return;
  }, [paymentData])

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Divider: {
              colorSplit: '#2a2a35',
              lineWidth: 2
            },
          },
        }}
      >
        <div className='container payment-custom-style'>
          <div className='custom-divider'></div>
          <Result
            status="success"
            title="Payment success!"
            subTitle={paymentDetails(paymentData)}
            extra={[
              <Button className='return-candidates-btn' key='to_candidates' onClick={returnToCandidates}>
                View CV
              </Button>,
              <Button className='return-candidates-list-btn' key='to_candidates_list' onClick={returnToCandidatesList}>View Candidates</Button>,
            ]}
          />
        </div>

        {/* <Loader /> */}
      </ConfigProvider>
    </>
  )
}

export default PaymentSuccess
import React, {useEffect, useState} from 'react';
import './password.css';
import apiService from "../../services/api.service";
import {useNavigate, useOutletContext} from "react-router-dom";

import {Alert, Form, Input, ConfigProvider, Button} from 'antd';

const ChangePassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [company, messageApi] = useOutletContext();

    const onChangePasswordFunc = async () => {

        console.log("onPublishJob form", form.getFieldValue())
        const {
            old_password,
            new_password,
            repeat_new_password
        } = form.getFieldValue();

        console.log('getFieldValue', old_password, new_password, repeat_new_password)

        const passObj = {
            oldPassword: old_password,
            password: repeat_new_password
        }

        try {
            let resp = await apiService.onChangePassword({...passObj})

            if (resp.status === 200) {
                messageApi.open({
                    className: 'open-message-style',
                    duration: 5,
                    type: 'success',
                    content: 'You have successfully changed the password',
                });
                form.resetFields()
            }

            console.log('resp change pass', resp)
            // setStateDataUser(resp.data)
        } catch (error) {
            console.log("onChangePasswordFunc error", error)
            messageApi.open({
                className: 'open-message-style',
                duration: 5,
                type: 'error',
                content: error.response.data.message,
            });
        }
    }

    // useEffect(() => {
    //     console.log('newPass', newPass)
    // }, [newPass])

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Input: {
                            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                            activeBorderColor: '#732135',
                            hoverBorderColor: '#732135',
                            colorBorder: '#aeaeae',
                        },
                    },
                }}
            >
                <Form
                    name="changePassword"
                    style={{
                        // maxWidth: 600,
                    }}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    labelAlign='left'
                >
                    <Alert style={{marginBottom: '20px'}} message="To change the password, it is necessary to enter the old password" />

                    <Form.Item
                        label="Old password"
                        name="old_password"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="New password"
                        name="new_password"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    {/* Field */}
                    <Form.Item
                        label="Confirm new password"
                        name="repeat_new_password"
                        dependencies={['new_password']}
                        rules={[
                            {
                                required: true,
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 16,
                        }}
                    >
                        <Button onClick={onChangePasswordFunc} block className='change-passbord-btn'>
                            Save new password
                        </Button>
                    </Form.Item>
                </Form>
            </ConfigProvider>
        </>
    )
}

export default ChangePassword
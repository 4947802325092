import React, {useState, useEffect} from "react";
import "./App.css";
import "./assets/css/global.css";
import "./assets/css/media-queries.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import CompanyDetails from "./components/company-details/CompanyDetails";
import ComapnyProfile from "./components/company-profile/CompanyProfile";
import {useLocation} from 'react-router-dom';


import {Layout, Menu, Button, theme, Row, Col, Image} from "antd";
const {Sider, Content} = Layout;

const App = () => {
  let location = useLocation();

  useEffect(() => {
    console.log("location", location)
  }, [location]);

  return (
    <Layout className="App">
      <Header />
      <main>
        <CompanyDetails />
      </main>
      <Footer />
    </Layout>
  );
};
export default App;

import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    isJobOfferEdit: false,
}

const jobOfferSlice = createSlice({
    name: 'jobReducer',
    initialState,
    reducers: {
        setIsJobOfferEdit(state, action) {
            state.isJobOfferEdit = action.payload;
        },
    },
})

export default jobOfferSlice.reducer

export const {setIsJobOfferEdit} = jobOfferSlice.actions

// export const selectPostById = (state, postId) =>
//     state.posts.posts.find((post) => post.id === postId)


import React, {useState, useEffect} from 'react';
import "./company-details.css";
import CompanyDetailsStepOne from './CompanyDetailsStepOne';
import CompanyDetailsStepTwo from './CompanyDetailsStepTwo';
// import ComapnyDetailsStepThree from './ComapnyDetailsStepThree';
import apiService from "../../services/api.service";
import {useNavigate, useOutletContext} from "react-router-dom";
import {setIsModalOpen} from '../../slices/login';
import {useDispatch} from 'react-redux';

import {Layout, Flex, Steps, Col, Row, Button, message, Modal, Image, Form} from 'antd';
const {Header, Footer, Sider, Content} = Layout;

const items = [
  {
    title: 'Company Details',
  },
  {
    title: 'About Company',
  },
  {
    title: 'Account',
  },
]

const CompanyDetails = () => {
  const [current, setCurrent] = useState(0);
  const [location, setLocation] = useState([]);
  const [logoCompany, setLogoCompany] = useState([]);
  const [coversCompany, setCoversCompany] = useState([]);
  const [companyInfoForm, setCompanyInfoForm] = useState(null);
  // const [fullForm, setFullForm] = useState({});
  const [company, messageApi] = useOutletContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(setIsModalOpen(true))
  };

  const stepItems = items.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const next = () => {
    console.log("form form", form.getFieldValue())
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const getLocationFunc = async () => {
    let resp = await apiService.getLocation();
    setLocation(resp.data['list']);
  }

  const toProfileFunc = () => {
    navigate("/profile");
  }

  // const fetchUploadCovers = async (data) => {
  //   await apiService.postUploadCovers(data)
  // }

  const onFinishForm = async (data) => {
    const fullForm = {...data, ...form.getFieldValue()};

    let pp = coversCompany.map(el => {
      return {coverId: el.response[0].id}
    })

    const currentYear = new Date().getFullYear();
    const {email, password, name, description, tax_id, phone1, phone2, street, housenumber, companyLogo, zipcode, year, employers, locationId, domainActivity} = fullForm;

    let finishForm = {
      email: email,
      password: password,
      name: name,
      description: description,
      tax_id: tax_id,
      phone1: phone1,
      phone2: phone2,
      street: street,
      // housenumber: housenumber,
      zipcode: zipcode,
      year: year ? year.getFullYear() : currentYear,
      employers: Number(employers),
      locationId: Number(locationId), //City ID
      domainActivity: domainActivity?.length > 0 ? domainActivity.map(item => Number(item)) : []
    }

    console.log("fullForm", fullForm)
    console.log("finishForm", finishForm)

    try {
      await apiService.registerNewCompany(finishForm).then(async response => {
        console.log("response", response)

        if (response.status === 200) {
          const respData = response.data;

          localStorage.setItem("accessToken", respData.accessToken);
          localStorage.setItem("refreshToken", respData.refreshToken);
          localStorage.setItem("profileStatus", 'company');
          if (logoCompany.length && logoCompany[0].response[0].id)
            await apiService.companySetLogo({
              logoId: logoCompany[0].response[0].id
            })
          if (pp.length)
            await apiService.companySetCovers({
              photos: pp
            })
          toProfileFunc()
        }

      });
    } catch (error) {
      console.log('onFinishForm error', error)
      messageApi.open({
        className: 'open-message-style',
        duration: 5,
        type: 'error',
        content: error.response.data.message,
      });
    }

    // setFullForm(fullForm)
  }

  const setCompnayLogoFunc = (ref, logo) => {
    setLogoCompany(logo)
  }

  useEffect(() => {
    getLocationFunc()
  }, [])

  useEffect(() => {
    if (!companyInfoForm) return;
  }, [companyInfoForm])

  return (
    <div className='company-details-section'>
      <div className="container">
        <Row className='company-details-step'>
          <Col span={24}>
            <Steps
              className='custom-step-style'
              current={current}
              items={stepItems}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {current === 0 && <CompanyDetailsStepOne form={form} setLogoCompany={setCompnayLogoFunc} setCoversCompany={setCoversCompany} setCompanyInfoForm={setCompanyInfoForm} location={location} />}
            {current === 1 && <CompanyDetailsStepTwo companyInfoForm={companyInfoForm} setCompanyInfoForm={setCompanyInfoForm} apiService={apiService} />}
            {/* {current === 2 && <ComapnyDetailsStepThree />} */}
          </Col>
        </Row>

        {current < stepItems.length - 1 && (
          <Row className='company-details-btn-block'>
            <Col xs={24} lg={{span: 11, offset: 13}}>
              <Row>
                <Col span={20} offset={2}>
                  <div className='custom-btn-block'>
                    {current > 0 && current < stepItems.length - 1 && (
                      <Button
                        style={{
                          margin: '0 8px',
                        }}
                        onClick={prev}
                      >
                        Previous
                      </Button>
                    )}
                    {current < stepItems.length - 1 && (
                      <>
                        {current === 0 && (
                          <>
                            <Button onClick={next}>
                              Next
                            </Button>
                          </>
                        )}
                        {current === 1 && (
                          <Button onClick={() => onFinishForm(companyInfoForm)}>
                            Sign in
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              <div className='company-details-login-block text-center'>
                <p>Already have an account? <Button type="link" onClick={showModal}>Log in</Button></p>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default CompanyDetails;

import React, {useState} from 'react';
import {Card, Button, Space, Badge, Flex} from 'antd';
import './subscription.css';
import {useTranslation} from 'react-i18next';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import {CloseCircleFilled, CheckCircleFilled} from '@ant-design/icons';
// Or, if you have to support IE9
// import "@egjs/react-flicking/dist/flicking-inline.css";
const {Meta} = Card;

const fakeSubscriptionPlan = [
    {
        name: 'Junker',
        monthPrice: '0',
        yearPrice: null,
        offerCount: '1',
        freeRefresh: null,
        duration: '30',
        appointments: null,
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: false,
        pictures: '1',
        save: null
    },
    {
        name: 'Knight',
        monthPrice: '199',
        yearPrice: null,
        offerCount: '1',
        freeRefresh: null,
        duration: '15',
        appointments: '20',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: false,
        pictures: '1',
        save: null
    },
    {
        name: 'Graph',
        monthPrice: '499',
        yearPrice: null,
        offerCount: '3',
        freeRefresh: '30',
        duration: 'Unlimited',
        appointments: '15',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: false,
        pictures: '3',
        save: null
    },
    {
        name: 'King',
        monthPrice: '799',
        yearPrice: '8600',
        offerCount: '7',
        freeRefresh: '20',
        duration: 'Unlimited',
        appointments: '10',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: true,
        pictures: '5',
        save: '10%'
    },
    {
        name: 'Empreror',
        monthPrice: '999',
        yearPrice: '9999',
        offerCount: 'Unlimited',
        freeRefresh: '10',
        duration: 'Unlimited',
        appointments: '5',
        boost: null,
        offerSymbols: '3000',
        notifications: true,
        extension: true,
        pictures: '10',
        save: '17%'
    }
]

const Subscription = () => {
    const {t, i18n} = useTranslation();
    // const [panels, setPanels] = useState([0, 1, 2, 3, 4]);

    const currencyFormat = (price) => price.toLocaleString("en-US", {style: "currency", currency: "EUR", minimumFractionDigits: 0, });

    // const refreshFree = (params) => {
    //     if (!params) {
    //         return (<CloseCircleFilled />)
    //     }
    // }

    return (
        <div className='company-subscription-section'>
            <div className="container">
                <div className="custom-padding">
                    <div className="subscription-information text-left">
                        <h2>Simple & Flexible Plans</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales <br /> sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                    </div>
                    <div className="subscaption-items-block">
                        <Space
                            direction="horizontal"
                            size="middle"
                            style={{
                                display: 'flex',
                            }}
                        >
                            <Flicking moveType="freeScroll" align='center' renderOnlyVisible={true} bound={true} className={i18n.language} >
                                {fakeSubscriptionPlan.map((plan, index) =>
                                    <Card
                                        key={index}
                                        hoverable
                                    >
                                        <div className="subscaption-card-item text-left w-100">
                                            <div className="item-header text-center">
                                                <h4>{t('plan.' + plan.name.toLowerCase())}</h4>
                                                <p>{t('plan.plan')}</p>
                                                <div className="month-price"><span>{currencyFormat(+plan.monthPrice)}</span> / {t('plan.month')}</div>
                                                {/* <div className="year-price">{plan.yearPrice && (<><span>{currencyFormat(+plan.yearPrice)}</span> / month</>)}</div> */}
                                                <Flex horizontal='true' justify='center' align='center'>
                                                    <div className="year-price">{plan.yearPrice && (<><span>{currencyFormat(+plan.yearPrice)}</span> / {t('plan.year')}</>)}</div>

                                                    {plan.name === 'Junker' && (
                                                        <div className='junker-style'>
                                                            <span>
                                                                {t('plan.zum')}
                                                            </span>
                                                            <span>
                                                                {t('plan.keine')}
                                                            </span>
                                                        </div>
                                                    )}

                                                    {plan.save && (
                                                        <span className='custom-badge-style'>{plan.save}<br /> {t('plan.save')}</span>
                                                    )}
                                                </Flex>
                                                <div className="free-month">
                                                    <span className='custom-badge-free-style'>{t('plan.free_months')}</span>
                                                </div>
                                            </div>
                                            <ul className="offer-list">
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.offerCount')}</p>
                                                    <span>{plan.offerCount !== 'Unlimited' ? plan.offerCount : t('plan.unlimited')}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.freeRefresh')}</p>
                                                    <span>{plan.freeRefresh ? t('plans.freeRefreshCount', {count: plan.freeRefresh}) : (<CloseCircleFilled />)}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.duration')}</p>
                                                    <span>{plan.duration !== 'Unlimited' ? plan.duration + ' T' : t('plan.unlimited')}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.appointments')}</p>
                                                    <span>{plan.appointments ? currencyFormat(+plan.appointments) : (<span>-</span>)}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.offerSymbols')}</p>
                                                    <span>{plan.offerSymbols}</span>
                                                </li>
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.notifications')}</p>
                                                    <span>{plan.notifications ? (<CheckCircleFilled />) : (<CloseCircleFilled />)}</span>
                                                </li>
                                                {/* <li className='offer-list-item'>
                                                <span>{plan.extension ? (<CheckCircleFilled />) : (<CloseCircleFilled />)}</span>
                                                <p>Free ad extension for new customers in case of dissatisfaction</p>
                                            </li> */}
                                                <li className='offer-list-item'>
                                                    <p>{t('plans.pictures')}</p>
                                                    <span>{plan.pictures ? plan.pictures : (<CloseCircleFilled />)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <Button className='subscription-btn'>{t('choose')}</Button>
                                    </Card>
                                )}
                            </Flicking>
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscription
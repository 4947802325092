import React, {useEffect, useState} from 'react';
import './payment.css';
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import apiService from "../../services/api.service";
import {useSelector} from 'react-redux';
import {IMG_URL, WEB_URL} from '../../constants/index';
import Loader from '../loader/Loader';
import UserNoImg from '../../assets/img/user_no_image.svg';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {Card, Flex, Button, Row, Col, Image} from 'antd';

const StripeCheckoutForm = ({companySubscription}) => {
    let {state} = useLocation();
    let {id} = useParams();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [stateDataUser, setStateDataUser] = useState(null);

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState(false);

    const categories = useSelector(state => state.launch.jobOfferCategory);

    const currencyFormat = (price) => price.toLocaleString("en-US", {style: "currency", currency: "EUR", minimumFractionDigits: 0, });

    const getUserInfoLikeFunc = async (likeId) => {
        console.log('likeId', likeId)
        try {
            let resp = await apiService.getUserInfoLike({likeId: likeId})
            setStateDataUser(resp.data)
        } catch (error) {
            console.log("getUserInfoLikeFunc error", error)
        }
    }

    const getUserImage = (src) => {
        if (src) {
            return IMG_URL + '/' + src
        } else {
            return UserNoImg
        }
    }

    const onPurchaseCandidate = () => {
        console.log('onPurchaseCandidate')
    }

    const getCandidatesCategories = () => {
        const spliceArr = stateDataUser.User.profile.categories

        spliceArr.map(item => {
            item.title = categories.find(el => el.id === item.joboffercategoryId).title
        })

        return (
            <>
                {spliceArr.map((item, index) => (
                    <span key={item.id}>
                        {index <= 1 && (
                            <p>{item.title} {index === 1 ? '...' : ','}</p>
                        )}
                    </span>
                ))}
            </>
        )
    }

    const paymentElementOptions = {
        layout: "tabs"
    }

    useEffect(() => {
        // if (!Object.keys(state).length) return

        console.log('state', state)

        if (!state?.likeId) {
            getUserInfoLikeFunc(id)
        } else {
            getUserInfoLikeFunc(state.likeId)
        }

    }, [state])

    useEffect(() => {
        if (!stateDataUser) return;
        console.log("stateDataUser", stateDataUser)
    }, [stateDataUser])

    useEffect(() => {
        if (!companySubscription) return;
        // console.log('companySubscription', companySubscription)
    }, [companySubscription])

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            console.log('paymentIntent', paymentIntent)
            setIsLoading(true)
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    setStatus(true)
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    setStatus(false)
                    break;
                default:
                    setMessage("Something went wrong.");
                    setStatus(false)
                    break;
            }
        });

        setTimeout(() => setIsLoading(false), 2500)
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // navigate(`/payment/error/${id}/${paymentIntent.id}`)

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${WEB_URL}/payment/processing/${id}/`,
            }
        });

        console.log('error ggggg', error)

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
            navigate(`/payment/error/${stateDataUser.jobId}/${id}`, {state: {errorMessage: error.message}})
            console.log('11111')
        } else {
            console.log('22222')
            navigate(`/payment/error/${stateDataUser.jobId}/${id}`, {state: {errorMessage: error.message}})
            setMessage("An unexpected error occurred.");
        }
    };

    const getCandidatesPyment = async () => {
        setIsLoading(true);

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (status) {
            try {
                const response = (await stripe.retrievePaymentIntent(clientSecret)).paymentIntent

                console.log('response ttttt', response)
                const payment = await apiService.onStripePay({
                    jobId: stateDataUser.jobId,
                    likeId: stateDataUser.id,
                    transactionId: response.id,
                    method: response.payment_method_types[0],
                    sum: response.amount / 100
                })

                console.log('payment rrrrr', payment)

                if (payment.data.success) {
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 2500)
                    navigate(`/payment/success/${id}/${response.id}`)
                }
                else {
                    navigate(`/payment/error/${id}/${response.id}`)
                }
            } catch (error) {
                console.log(error)
                // navigate(`/payment/error/${id}/${response.id}`)
            }
        }
    }

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2500)

        getCandidatesPyment()

        console.log('status', status)
    }, [status])

    return (
        <>
            {isLoading
                ? <Loader loading={isLoading} />
                : (
                    <>
                        {stateDataUser && (
                            <div className='container custom-stripe-style'>
                                <div className="job-information text-left">
                                    <h2>Payment</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales <br /> sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                                    <Card bordered={true} className='payment-candidates-block'>
                                        <Row gutter={16} justify="space-between" align="middle">
                                            <Col>
                                                <Image
                                                    width={75}
                                                    src={getUserImage(stateDataUser.User.photo?.path)}
                                                    preview={false}
                                                />
                                            </Col>
                                            <Col>
                                                <h4>{stateDataUser.User.firstName} {stateDataUser.User.lastName}</h4>
                                                {getCandidatesCategories()}
                                            </Col>
                                            <Col>
                                                <h4>Candidates contacts</h4>
                                                <Flex gap="middle" horizontal='true' className='contacts-block'>
                                                    <div className='mr-3'>
                                                        <p><span className='contacts-label'>Email:</span><span>{stateDataUser.User.email}</span></p>
                                                        <p><span className='contacts-label'>Phone:</span><span>{stateDataUser.User.phone}</span></p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span className='contacts-label'>Driving license: </span>
                                                            {stateDataUser.User.CVState.additional_details.driving_license.map(item => (
                                                                <span key={item.id}>{item.category}, </span>
                                                            ))}
                                                        </p>
                                                        <p>
                                                            <span className='contacts-label'>Languages: </span>
                                                            {stateDataUser.User.CVState.additional_details.languages.map(item => (
                                                                <span key={item.id}>{item.language}, </span>
                                                            ))}
                                                        </p>
                                                    </div>
                                                </Flex>
                                            </Col>
                                            <Col>
                                                <h4>Total - <span className='total-price'>{currencyFormat(+companySubscription?.price_match)}</span></h4>
                                                <p>Price for personal <br /> data</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                                <form id="payment-form" onSubmit={handleSubmit}>
                                    <PaymentElement options={paymentElementOptions} />
                                    <Button id="submit" htmlType="submit" className='purchase-candidates-btn' >Purchase</Button>
                                    {message && <h5 id="payment-message">{message}</h5>}
                                </form>
                            </div>
                        )}
                    </>
                )
            }

        </>
    );
};

export default StripeCheckoutForm;
import React, {useState, useEffect} from 'react';
import {useNavigate, useOutletContext, Link} from "react-router-dom";
import {Layout, Flex, Drawer, Button, Modal, Menu, Space, Upload, ConfigProvider, Card} from 'antd';
import './company-profile.css';
import EditCompanyProfileModal from './EditCompanyProfileModal';
import ChangePassword from '../password/ChangePassword';

import apiService from "../../services/api.service";
import {API_URL, IMG_URL} from "../../constants";
import {useSelector} from 'react-redux';

import CompanyNoImg from '../../assets/img/user_no_image.svg';
import {ReactComponent as ProfileIcon} from '../../assets/img/icon_profile.svg';
import {ReactComponent as NotificationIcon} from '../../assets/img/icon_notifications.svg';
import {ReactComponent as PasswordIcon} from '../../assets/img/icon_password.svg';

import UpgradeBanner from '../upgrade/UpgradeBanner';
import {PlusOutlined, ArrowRightOutlined, MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
const {Header, Footer, Sider, Content} = Layout;

const getMenuItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const menuItems = [
  getMenuItem('Profile information', 'profile', <ProfileIcon />),
  getMenuItem('Change Password', 'change_password', <PasswordIcon />),
  // getMenuItem('Notifications', 'notification', <NotificationIcon />),
];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ComapnyProfile = () => {
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [profileKeyName, setProfileKeyName] = useState('');
  const [menuItemKey, setMenuItemKey] = useState('profile');
  const [domainActivities, setDomainActivities] = useState([]);
  const [company, setCompany] = useOutletContext();
  const [contactCompany, setContactCompany] = useState([]);
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ]);
  const categories = useSelector(state => state.launch.jobOfferCategory);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({fileList: newFileList}) => setFileList(newFileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const showEditModal = (keyName) => {
    setOpenEditModal(true);
    setProfileKeyName(keyName)
  };

  const onClickMenuItem = (e) => {
    console.log('e e e e e e e ', e)
    setMenuItemKey(e.key)
  };

  const getCompanyImage = (src) => {
    let img = {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: CompanyNoImg,
    }

    if (src) {
      img.url = IMG_URL + '/' + src
    }
    setFileList([img])
    return img
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("company eee", company);

    const filteredActivities = [];
    const contactCompanyObj = {
      'name': 'Name:',
      'tax_id': 'Tax ID:',
      'phone1': 'Phone 1:',
      'phone2': 'Phone 2:',
      'Location.Country.name': 'Country:',
      'Location.name': 'City:',
      'street': 'Street:',
      'zipcode': 'Zip Code:',
    }

    categories.forEach(cat => {
      if (company) {
        let found = company?.CompanyDomainActivity?.find(item => cat.id.toString() === item.joboffercategoryId.toString());
        if (found)
          filteredActivities.push({
            ...cat,
            customId: found.id,

          })
      }
    })

    if (company) {
      let contactObj = []
      for (const property in contactCompanyObj) {
        const objContact = {
          keyName: property,
          label: contactCompanyObj[property],
          value: company[property]
        }
        contactObj.push(objContact)

        if (property === 'Location.Country.name') {
          objContact.value = company.Location?.Country?.name
        }

        if (property === 'Location.name') {
          objContact.value = company.Location?.name
        }
      }
      setContactCompany(contactObj)
    }

    setDomainActivities(filteredActivities);
    getCompanyImage(company.logo?.path)
  }, [company])

  useEffect(() => {
    console.log("categories rrr", categories)
  }, [categories])

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBorderRadius: '0',
            itemSelectedBg: 'transparent',
            itemActiveBg: 'transparent',
            itemHoverBg: '#f2e7ea',
            itemMarginInline: 0,
            itemHoverColor: '#732135',
          },
          Button: {
            defaultBorderColor: '#732135',
            defaultHoverBorderColor: '#732135',
            defaultActiveBorderColor: '#732135',
            lineWidth: 2,
          },
        },
      }}
    >
      <div className="container">
        <div className="custom-padding">

          <Card className="mobile-company-side-block">
            <Button
              className="sider-collapsed-btn mobile-company-sider-btn"
              type="text"
              icon={!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={showDrawer}
            />
            <Drawer
              title="Basic Drawer"
              placement='left'
              closable={false}
              onClose={onClose}
              open={open}
              key='left'
            >
              <Sider width='100%' theme='light'>
              <Flex align="center" justify="space-between" vertical className='h-100'>
        <Menu
          onClick={onClickMenuItem}
          className='company-profile-menu'
          defaultSelectedKeys={[menuItemKey]}
          mode="inline"
          items={menuItems}
        />

        <Flex justify='center' align='center' vertical className='company-name-section w-100'>
          <div className="company-info-block">
            <Upload
              action={API_URL + "/company/upload/photos"}
              headers={apiService.authHeader()}
              listType="picture-circle"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              name='photos'
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <h2 className='company-name text-center'>{company.name}</h2>
            <h4 className='company-email text-center'>{company.email}</h4>
          </div>

          <UpgradeBanner />

          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
        </Flex>
      </Flex>
              </Sider>
            </Drawer>
          </Card>

          <Layout className='company-details-section company-details-step-three'>
            <Sider width='25%' theme='light' className='company-sider-block'>
            <Flex align="center" justify="space-between" vertical className='h-100'>
        <Menu
          onClick={onClickMenuItem}
          className='company-profile-menu'
          defaultSelectedKeys={[menuItemKey]}
          mode="inline"
          items={menuItems}
        />

        <Flex justify='center' align='center' vertical className='company-name-section w-100'>
          <div className="company-info-block">
            <Upload
              action={API_URL + "/company/upload/photos"}
              headers={apiService.authHeader()}
              listType="picture-circle"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              name='photos'
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <h2 className='company-name text-center'>{company.name}</h2>
            <h4 className='company-email text-center'>{company.email}</h4>
          </div>

          <UpgradeBanner />

          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
        </Flex>
      </Flex>
            </Sider>
            <Layout>
              <Content>
                {menuItemKey === 'profile' && (
                  <div className="company-information-block">
                    {/* <div className="information">
                    <div className="edit-block">
                      <h2>Company Information</h2>
                      <Button onClick={() => showEditModal('companyInfo')}>
                        Edit
                      </Button>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                  </div> */}
                    <div className="about">
                      <div className="edit-block">
                        <h2>About</h2>
                        <Button onClick={() => showEditModal('aboutCompany')}>
                          Edit
                        </Button>
                      </div>
                      <p>{company.description}</p>
                    </div>
                    <div className="contact">
                      <div className="edit-block">
                        <h2>Contact</h2>
                        <Button onClick={() => showEditModal('companyContact')}>
                          Edit
                        </Button>
                      </div>
                      {contactCompany.map(item => (
                        <div key={item.keyName} className='d-flex edit-info-company'>
                          <div className="contact-data-label">{item.label}</div>
                          <div className="contact-data-value">{item.value}</div>
                        </div>
                      ))}

                    </div>
                    <div className="activities">
                      <div className="edit-block">
                        <h2>Domain of activities</h2>
                        <Button onClick={() => showEditModal('domainActivities')}>
                          Edit
                        </Button>
                      </div>
                      <div className="activities-content">
                        {domainActivities && domainActivities.map((item, index) => (
                          <div style={{margin: '15px 0 20px'}} key={item.id}>
                            <h5>{item.title}</h5>
                            <p>{item.description}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {menuItemKey === 'change_password' && (
                  <div className="company-information-block change-pass-block">
                    <ChangePassword />
                  </div>
                )}

              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
      <EditCompanyProfileModal
        categories={categories}
        profileKeyName={profileKeyName}
        domainActivities={domainActivities}
        contactCompany={contactCompany}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        about={company.description}
        rawCompany={company}
      />
    </ConfigProvider>
  )
}

export default ComapnyProfile

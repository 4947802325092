import React from 'react';
import {Player, Controls} from '@lottiefiles/react-lottie-player';
import LoadingSquare from './loading_square.json';
import './loader.css';


const Loader = () => {
    return (
        <>
            <div className='custom-loading-style'>
                <Player
                    autoplay
                    loop
                    src={LoadingSquare}
                    style={{height: '300px', width: '300px'}}
                >
                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                </Player>
            </div>

        </>
    )
}

export default Loader
import React, {useEffect, useState} from 'react';
import './payment.css';
import Loader from '../loader/Loader';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import apiService from "../../services/api.service";

import {Button, Result, Divider, ConfigProvider} from 'antd';

const PaymentError = () => {
  let {id, jobId} = useParams();
  let navigate = useNavigate();
  let {state} = useLocation();

  const [paymentData, setPaymentData] = useState(null)

  const returnToCandidates = () => {
    navigate('/view-candidates-info/' + id)
  }

  const returnToCandidatesList = () => {
    navigate('/candidates-list/' + jobId)
  }

  const paymentErrorDetails = (errorMessage) => {
    return (
      <>
        {errorMessage && (
          <h3>{errorMessage}</h3>
        )}
      </>
    )
  }

  const returnToCandidatesPayment = () => {
    navigate('/payment/' + id)
  }

  useEffect(() => {
    if (!paymentData) return;
  }, [paymentData])

  useEffect(() => {
    if (!state) return;
    // console.log('state', state)
  }, [state])

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Divider: {
              colorSplit: '#2a2a35',
              lineWidth: 2
            },
          },
        }}
      >
        <div className='container payment-custom-style'>
          <div className='custom-divider'></div>
          <Result
            status="error"
            title="Payment failed :("
            subTitle={paymentErrorDetails(state.errorMessage)}
            extra={[
              <Button className='return-candidates-btn' key='to_candidates_payment' onClick={returnToCandidatesPayment}>
                Return to payment
              </Button>,
              <Button className='return-candidates-btn' key='to_candidates' onClick={returnToCandidates}>
                View CV
              </Button>,
              <Button className='return-candidates-list-btn' key='to_candidates_list' onClick={returnToCandidatesList}>View Candidates</Button>,
            ]}
          />
        </div>

        {/* <Loader /> */}
      </ConfigProvider>
    </>
  )
}

export default PaymentError
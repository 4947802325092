import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import CustomHeader from "../components/header/Header";
import CustomFooter from "../components/footer/Footer";
import CustomSider from '../components/sider/CustomSider';
import LoginModal from '../components/login-form/LoginModal';
import apiService from "../services/api.service";
import {useDispatch} from 'react-redux';
import {setWorkTimeType, setJobOfferCategory} from '../slices/launch';

import {Layout, notification, message, Space, Button} from 'antd';
import "../App.css";
import "../assets/css/global.css";
import "../assets/css/media-queries.css";
const {Header, Footer, Content} = Layout;

const Root = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [company, setCompany] = useState({});
    const [logged, setLogged] = useState(false);
    const accessToken = localStorage.getItem("accessToken");
    const profileStatus = localStorage.getItem("profileStatus");
    const [notifications, notificationContextHolder] = notification.useNotification();
    const [messageApi, contextHolder] = message.useMessage();

    const getCompanyFunc = async () => {
        try {
            await apiService.getCompany().then(response => {
                setCompany(response.data)
                setLogged(true)
            })
        } catch (error) {
            console.log("getCompanyFunc error", error)
            setLogged(false)
            if (error.response.status !== 200) {
                navigate("/login");
                setLogged(false)
            }
        }
    }

    const closeNotifications = () => {
        console.log(
            'Notification was closed. Either the close button was clicked or duration time elapsed.',
        );
    };

    const key = `open${Date.now()}`;
    const btn = (
        <Space>
            <div className="custom-btn-block">
                <Button
                    htmlType="submit"
                    block
                    className='w-100'
                    onClick={() => {
                        notifications.destroy(key)
                        navigate("/");
                    }}
                >
                    Create an account
                </Button>
            </div>
        </Space>
    );

    const getWorkTimeTypeFunc = async () => {
        let resp = await apiService.getLaunch();
        const workTypeResponse = resp.data['worktimetype'];
        const categories = resp.data['categories'];

        const workTypeObj = workTypeResponse.map(item => {
            return {
                keyName: item.name_key,
                label: item.text,
                value: item.id,
                id: item.id,
                ...item
            }
        })

        const workCategories = categories.map(item => {
            return {
                ...item,
                label: item.title,
                value: item.id
            }
        });

        dispatch(setWorkTimeType(workTypeObj))
        dispatch(setJobOfferCategory(workCategories))
    }

    useEffect(() => {
        getCompanyFunc();
        getWorkTimeTypeFunc();
        // messageApi.open({
        //     className: 'open-message-style',
        //     duration: 5,
        //     type: 'success',
        //     content: 'You have successfully changed the password',
        // });
    }, [accessToken])

    useEffect(() => {
        if (profileStatus && profileStatus !== 'company') {
            notifications.open({
                message: 'Notification Title',
                description:
                    'A function will be be called after the notification is closed (automatically after the "duration" time of manually).',
                btn,
                key,
                onClose: closeNotifications,
            });
            navigate("/");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("profileStatus");
        }
    }, [profileStatus])

    return (
        <Layout>
            {contextHolder}
            {notificationContextHolder}
            <Header className="header">
                <CustomHeader />
            </Header>
            <Layout hasSider className="App">
                {logged && (<CustomSider company={company} />)}
                <Content>
                    <Outlet context={[company, messageApi]} />
                    <Footer>
                        <CustomFooter />
                    </Footer>
                </Content>
            </Layout>
            <LoginModal />
        </Layout>
    );
}

export default Root;

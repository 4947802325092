import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import './password.css';
import '../login-form/login.css';
import apiService from "../../services/api.service";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {setIsModalOpen} from '../../slices/login';
import LoginImg from '../../assets/img/image_login.png';
import {Layout, Flex, Steps, Col, Row, Button, Modal, Image, Form, Checkbox, Input, ConfigProvider, Space} from 'antd';

const RecoverPassword = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(state => state.login.isModalOpen);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [company, messageApi] = useOutletContext();

    const [disabledButton, setDisabledButton] = useState(false)

    const showModal = () => {
        dispatch(setIsModalOpen(true))
    };

    const onRecoverPasswordFunc = async (values) => {
        try {
            await apiService.onRecoverPassword({
                email: values.email
            }).then(response => {
                console.log("onRecoverPasswordFunc response", response)
                if (response.data.success === true) {
                    setDisabledButton(true)
                    messageApi.open({
                        className: 'open-message-style',
                        duration: 10,
                        type: 'success',
                        content: 'I have sent the instructions for resetting the password to the email you indicated. Please check your mailbox and follow the instructions',
                    });
                }
            });
        } catch (error) {
            console.log("Login error", error)
            messageApi.open({
                className: 'open-message-style',
                duration: 5,
                type: 'error',
                content: error.response.data.message,
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.resetFields()
    }, [isModalOpen])

    return (
        <div className='login-modal-block container' >
            <ConfigProvider
                theme={{
                    components: {
                        Input: {
                            activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                            activeBorderColor: '#732135',
                            hoverBorderColor: '#732135',
                            colorBorder: '#aeaeae',
                        },
                        Checkbox: {
                            colorPrimary: '#732135',
                            colorPrimaryHover: '#732135',
                            colorBorder: '#aeaeae',
                        }
                    },
                }}
            >
                <Row>
                    <Col xs={24} lg={12} className='hello-block'>
                        <Image
                            preview={false}
                            src={LoginImg}
                        />
                        <h3 className='text-center'>Very good works are waiting for you.</h3>
                        <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </Col>
                    <Col xs={24} lg={12} className='login-block'>
                        <h2>Recover Password</h2>
                        <p>We’ll e-mail you instructions on how to reset your password.</p>
                        <Form
                            disabled={disabledButton}
                            form={form}
                            name="loginForm"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onRecoverPasswordFunc}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email:"
                                name="email"
                                rules={[
                                    {
                                        // required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item className='sign-in-style'>
                                <div className="custom-btn-block">
                                    <Button htmlType="submit" block className='w-100'>
                                        Reset password
                                    </Button>
                                </div>
                            </Form.Item>

                            <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                <p className='have-account'>Don’t you have an account?</p>
                                <Link to='/' className='create-account-link'>Create an account</Link>
                            </Flex>

                            <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                <p className='have-account'>Don’t you have an account?</p>
                                <Link to='/reset-password/token' className='create-account-link'>Reset password</Link>
                            </Flex>

                            <div className='company-details-login-block'>
                                <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                    <p className='have-account'>Go back to</p>
                                    <Button type="link" className='recover-login-btn' onClick={showModal}>Log in</Button>
                                </Flex>
                            </div>
                        </Form>
                        <div className="login-copyright-block">
                            <p className='text-center'>Copyright 2024 JOB123 LTD.</p>
                            <Flex gap="middle" align="center" justify="space-between" horizontal='true'>
                                <Link to='/privacy-policy' target='_blank' >Privacy Policy</Link>
                                <Link to='/term-of-use' target='_blank' >Term of Use</Link>
                            </Flex>
                        </div>
                    </Col>
                </Row>
            </ConfigProvider>
        </div>
    )
}

export default RecoverPassword
import React, {useEffect, useState} from 'react';
import './payment.css';
import apiService from "../../services/api.service";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {IMG_URL} from '../../constants/index';
import UserNoImg from '../../assets/img/user_no_image.svg';
import {Space, Table, Button, Row, Col, Input, ConfigProvider, message, Flex, Image} from 'antd';
const {Column} = Table;

const PaymentList = () => {
    const navigate = useNavigate();
    const [paymentsList, setPaymentsList] = useState([]);

    const currencyFormat = (price) => price.toLocaleString("en-US", {style: "currency", currency: "EUR", minimumFractionDigits: 0, });

    const getPaymentHistoryFunc = async () => {
        const params = {
            pagination: {
                take: 100,
                skip: 0
            }
        }

        try {
            let resp = await apiService.getPaymentHistory(params)
            setPaymentsList(resp.data)
        } catch (error) {
            console.log("getUserInfoLikeFunc error", error)
        }
    }

    const getUserImage = (src) => {
        if (src) {
            return IMG_URL + '/' + src
        } else {
            return UserNoImg
        }
    }

    useEffect(() => {
        getPaymentHistoryFunc()
    }, [])

    useEffect(() => {
        if (!paymentsList) return;
        console.log('paymentsList', paymentsList)
    }, [paymentsList])

    return (
        <div className="container">
            <div className="custom-padding">
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                activeBorderColor: '#732135',
                                hoverBorderColor: '#732135',
                                colorBorder: '#aeaeae',
                                controlHeight: 38,
                                activeShadow: '0 0 0 2px rgba(115, 34, 52, 0.1);',
                            },
                            Button: {
                                primaryShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                                controlHeight: 32,
                                defaultColor: '#732135',
                                defaultBorderColor: '#732135',
                                defaultActiveBorderColor: '#732135',
                                defaultHoverBorderColor: '#732135',
                                defaultHoverBg: '#732135',
                                defaultHoverColor: '#ffffff'
                            },
                        },
                    }}
                >
                    <div className="job-information text-left">
                        <h2>Payments</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sodales <br /> sit amet nunc et vehicula. Mauris sed lectus nisi.</p>
                    </div>

                    <div className="job-table candidates-table">
                        <Table
                            scroll={{
                                x: 768,
                            }}
                            dataSource={paymentsList}
                            bordered={false}
                            rowKey={(data => data.id.toString())}
                        >
                            <Column
                                className='candidates-info'
                                title="Candidates"
                                dataIndex="candidate"
                                render={(user, {id}) => (
                                    <Flex key={id} justify='flex-start' align='center'>
                                        <Image
                                            width={50}
                                            src={getUserImage(user.photo?.path)}
                                            preview={false}
                                        />
                                        <span className='candidates-name'>
                                            {user.firstName} {user.lastName}
                                        </span>
                                    </Flex>
                                )}
                            />
                            <Column
                                className='user-profession'
                                title="Date"
                                dataIndex="createdAt"
                                // width={130}
                                render={(createdAt, {id}) => (
                                    <div key={id}>
                                        {new Date(createdAt).toLocaleDateString()}
                                    </div>
                                )}
                            />
                            <Column
                                className='user-profession'
                                title="Transaction ID"
                                dataIndex="transactionId"
                                // width={130}
                                render={(transactionId, {id}) => (
                                    <div key={id}>
                                        {transactionId}
                                    </div>
                                )}
                            />
                            {/* <Column
                            className='user-profession'
                            title="Type"
                            dataIndex="type"
                            // width={130}
                            render={(type, {id}) => (
                                <div key={id}>
                                    {type.toUpperCase()}
                                </div>
                            )}
                        /> */}
                            <Column
                                className='user-profession'
                                title="Method"
                                dataIndex="method"
                                // width={130}
                                render={(method, {id}) => (
                                    <div key={id}>
                                        {method.toUpperCase()}
                                    </div>
                                )}
                            />
                            <Column
                                className='user-profession'
                                title="Amount"
                                dataIndex="sum"
                                // width={130}
                                render={(sum, {id}) => (
                                    <div key={id}>
                                        {currencyFormat(Number(sum))}
                                    </div>
                                )}
                            />
                            <Column
                                className='job-action'
                                title="CV"
                                align='center'
                                width={150}
                                render={(data) => (
                                    <Space size="middle" key={data.id.toString()}>
                                        <Button
                                            className='candidates-list-btn'
                                            onClick={(event) => {
                                                event.preventDefault()
                                                event.stopPropagation()
                                                navigate("/view-candidates-info/" + data.targetId, {state: {likeId: data.targetId}});
                                            }}
                                        >
                                            View CV
                                        </Button>
                                    </Space>
                                )}
                            />
                        </Table>
                    </div>
                </ConfigProvider>
            </div>
        </div>
    )
}

export default PaymentList
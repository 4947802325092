export const API_URL = process.env.REACT_APP_API_URL;
export const IMG_URL = process.env.REACT_APP_IMG_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;

export const DRIVING_LICENCE = [
    {
        label: 'AM',
        value: 1
    },
    {
        label: 'A1',
        value: 2
    },
    {
        label: 'A2',
        value: 3
    },
    {
        label: 'A',
        value: 4
    },
    {
        label: 'B1',
        value: 5
    },
    {
        label: 'B',
        value: 6
    },
    {
        label: 'C1',
        value: 7
    },
    {
        label: 'C',
        value: 8
    },
    {
        label: 'D1',
        value: 9
    },
    {
        label: 'D',
        value: 10
    },
    {
        label: 'BE',
        value: 11
    },
    {
        label: 'C1E',
        value: 12
    },
    {
        label: 'CE',
        value: 13
    },
    {
        label: 'D1E',
        value: 14
    },
    {
        label: 'DE',
        value: 15
    },
    {
        label: 'L',
        value: 16
    },
    {
        label: 'T',
        value: 17
    },
    {
        label: 'Q',
        value: 18
    },
]

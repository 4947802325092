import axios from "axios";
import {API_URL} from "../constants";

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("profileStatus");
    }
    return Promise.reject(error);
});

const authHeader = () => {
    const timestamp = Date.now();
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    return {'Authorization': 'Token ' + accessToken, 'timestamp': timestamp};
}

const getCompany = () => {
    return axios.get(API_URL + '/company', {headers: authHeader()})
}

const getLaunch = () => {
    return axios.get(API_URL + '/launch')
}

const getLocation = () => {
    return axios.get(API_URL + '/location/list')
}

const registerNewCompany = (data) => {
    return axios.post(API_URL + '/company/register', data)
}

const postUploadPhoto = (data) => {
    return axios.post(API_URL + '/company/upload/photo', data, {headers: authHeader()})
}

const postUploadCovers = (data) => {
    return axios.post(API_URL + '/company/upload/covers', data, {headers: authHeader()})
}

const companySetCovers = (data) => {
    return axios.post(API_URL + '/company/set/covers', data, {headers: authHeader()})
}
const companySetLogo = (data) => {
    return axios.post(API_URL + '/company/set/logo', data, {headers: authHeader()})
}

const addNewJob = (data) => {
    return axios.post(API_URL + '/job/add', data, {headers: authHeader()})
}

const editJob = (data) => {
    return axios.post(API_URL + '/job/edit', data, {headers: authHeader()})
}
const editCompany = (data) => {
    return axios.post(API_URL + '/company/edit', data, {headers: authHeader()})
}
const onLoginUser = (data) => {
    return axios.post(API_URL + '/auth/login', data, {headers: authHeader()})
}

const onChangePassword = (data) => {
    return axios.post(API_URL + '/auth/change-password', data, {headers: authHeader()})
}

const onRecoverPassword = (data) => {
    return axios.post(API_URL + '/auth/recover-password', data, {headers: authHeader()})
}

const onResetPassword = (data) => {
    return axios.post(API_URL + '/auth/reset-password', data, {headers: authHeader()})
}

const getJobOffers = (data) => {
    return axios.post(API_URL + '/company/jobs', data, {headers: authHeader()})
}

const setJobStatus = (data) => {
    return axios.post(API_URL + '/job/status', data, {headers: authHeader()})
}

const onDeleteJob = (data) => {
    return axios.post(API_URL + '/job/delete', data, {headers: authHeader()})
}

const getJobDetails = (data) => {
    return axios.post(API_URL + '/job/details', data, {headers: authHeader()})
}

const getUserInfoLike = (data) => {
    return axios.post(API_URL + '/job/info/like', data, {headers: authHeader()})
}

const getWhoLikedMyJob = (data) => {
    return axios.post(API_URL + '/company/jobs/likes', data, {headers: authHeader()})
}

const getClientSecretStripe = (data) => {
    return axios.post(API_URL + '/payment/create-payment-intent', data, {headers: authHeader()})
}

const getFreeCandidates = (data) => {
    return axios.post(API_URL + '/payment/simple-pay', data, {headers: authHeader()})
}

const onStripePay = (data) => {
    return axios.post(API_URL + '/payment/stripe-pay', data, {headers: authHeader()})
}

const getPaymentHistory = (data) => {
    return axios.post(API_URL + '/payment/history', data, {headers: authHeader()})
}

const apiService = {
    getLaunch,
    getLocation,
    registerNewCompany,
    postUploadPhoto,
    postUploadCovers,
    addNewJob,
    getCompany,
    onLoginUser,
    authHeader,
    getJobOffers,
    setJobStatus,
    onDeleteJob,
    getJobDetails,
    editJob,
    getWhoLikedMyJob,
    getClientSecretStripe,
    getFreeCandidates,
    getUserInfoLike,
    onStripePay,
    getPaymentHistory,
    onChangePassword,
    companySetCovers,
    companySetLogo,
    editCompany,
    onRecoverPassword,
    onResetPassword
};

export default apiService;

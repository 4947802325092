import {React, useEffect, useState} from "react";
import {Form, Radio, Flex} from 'antd';
import {Link} from "react-router-dom";
import Logo from '../../assets/img/logo.svg';
import {useTranslation} from 'react-i18next';
import "./header.css";

const lngs = {
    en: {nativeName: 'English'},
    de: {nativeName: 'Deutsch'}
};

const Header = () => {
    const {t, i18n} = useTranslation();
    const [languages, setLanguages] = useState('en');

    const langOption = Object.keys(lngs).map(lng => lng)

    const handleLogoLink = () => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            return '/profile'
        } else {
            return '/'
        }
    }

    useEffect(() => {
        const enLangElement = document.querySelector('#custom-switch-en')
        const deLangElement = document.querySelector('#custom-switch-de')

        if (enLangElement && languages === 'en') {
            enLangElement.classList.add('checked-language-en')
            enLangElement.classList.remove('checked-language-de')
        }
        if (deLangElement && languages === 'de') {
            deLangElement.classList.add('checked-language-de')
            deLangElement.classList.remove('checked-language-en')
        }
        // console.log("languages", languages)
        i18n.changeLanguage(languages)
    }, [languages])

    return (
        // <header className="header">
        <Flex className="header-block" justify={'center'} align={'center'}>
            <div className='header-nav-menu'>
                <Link to={handleLogoLink()} className="navbar-brand">
                    <img
                        src={Logo}
                        className="d-inline-block align-top"
                        alt="Job 123 logo"
                    />
                </Link>
                {/* <a href="/" className="navbar-brand">
                    <img
                        src={Logo}
                        className="d-inline-block align-top"
                        alt="Job 123 logo"
                    />
                </a> */}
            </div>

            <Flex justify={'center'} align={'center'} style={{padding: '0 130px'}}>
                <h4>{t('get_job')}</h4>
                <div className='header-menu-position change-language-block mb-4 mb-md-0'>
                    <Form>
                        <Form.Item>
                            <Radio.Group
                                defaultValue={languages}
                                options={langOption}
                                id={'lang-radio-' + languages}
                                onChange={(event) => {
                                    i18n.changeLanguage(languages)
                                    setLanguages(event.target.value)
                                }}
                            >
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </div>
            </Flex>
        </Flex>
        // </header>

    );
}

export default Header;
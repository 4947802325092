import {configureStore} from '@reduxjs/toolkit'
import {combineReducers} from '@reduxjs/toolkit'

import {setupListeners} from '@reduxjs/toolkit/query'
import loginReducer from "./login"
import launchReducer from "./launch"
import jobReducer from "./job"
import storage from 'redux-persist/lib/storage/session'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
const persistConfig = {
    key: 'root',
    storage: storage
}
export const appReducer = combineReducers({
    login: loginReducer,
    launch: launchReducer,
    jobOffer: jobReducer
})

const rootReducers = (state, action) => {
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
})
setupListeners(store.dispatch)
export default store
